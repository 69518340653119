<template>
  <div class="app">
    <div class="app-section app-section_header">
      <template v-if="$route.query.source === 'cc_extranet'">
        <h1 v-if="showID"
            class="is-size-5"
        >
          Hakemus Lähetetty. Uuden hakemuksen ID:
        </h1>
        <p class="is-size-5 my-2"><b>{{ id }}</b></p>
        <!--<button class="button is-primary is-rounded mt-1" @click="copyID">
        Kopioi ID
      </button> -->
        <button @click="submit">
          Jatka
        </button>
      </template>
      <template v-else>
        <template v-if="$store.state.whitelabel">
          <p class="title is-4 has-text-rlgreen">Kiitos lainahakemuksesta</p>
          <div class="content">
            <p>Lainahakemuksesi on välitetty lainojen kilpailuttamiseen erikoistuneelle kumppanillemme Rahalaitokselle, joka hoitaa lainasi kilpailuttamisen tästä eteenpäin. Olet saanut heiltä vahvistuksen tästä sähköpostiisi ja puhelimeesi.</p>
          </div>
        </template>
        <p class="title is-4 has-text-rlgreen">Tarkista tiedot</p>
        <form @submit.prevent>
          <p class="title is-6 has-text-rlgreen">Varmista vielä seuraavat tiedot, jotta pystymme palvelemaan sinua entistä sujuvammin:</p>
          <div v-if="$store.state.whitelabel && $store.state.whitelabel.iframe" class="content">
            <p>Lainahakemuksenne on nyt käsittelyssä. Voitte seurata lainaprosessin etenemistä reaaliajassa kirjautumalla Oma Rahalaitos -palveluun.</p>
            <p>Saatte alustavan lainapäätöksen hakemuksellenne jopa minuuteissa. Lainapäätöksistä ilmoitetaan myös sähköpostilla sekä tekstiviestillä.</p>
          </div>
          <div class="columns is-multiline">
            <div class="column is-6">
              <form-input
                ref="bruttotulot"
                key="confirmation.bruttotulot"
                v-model="confirmation.bruttotulot"
                v-validate="'required|numeric_spaces|more_than:nettotulot'"
                :error="errors.first('bruttotulot')"
                :mask="{ mask: masks.euro, guide: false }"
                :icon="['far', 'euro-sign']"
                name="bruttotulot"
                type="tel"
                label="Bruttotulot kuukaudessa"
                placeholder="Bruttotulot kuukaudessa"
                tooltip="Ilmoita kuukausitulosi ennen veroja. Bruttokuukausitulojen täytyy olla suuremmat kuin nettokuukausitulojen."
              />
            </div>
            <div class="column is-6">
              <form-input
                ref="nettotulot"
                key="confirmation.nettotulot"
                v-model="confirmation.nettotulot"
                v-validate="{
                  required: true,
                  numeric_spaces: true,
                  min_value_stripped: 600,
                  percentage_difference: {
                    target: confirmation.bruttotulot,
                    percentage: 39
                  },
                  less_than: 'bruttotulot'
                }"
                :error="errors.first('nettotulot')"
                :mask="{ mask: masks.euro, guide: false }"
                :icon="['far', 'euro-sign']"
                name="nettotulot"
                type="tel"
                label="Nettotulot kuukaudessa"
                placeholder="Nettotulot kuukaudessa"
                tooltip="Ilmoita kuukausitulosi verojen jälkeen. Nettokuukausitulojen täytyy olla vähintään 600 euroa."
              />
            </div>
            <div class="column is-6">
              <form-input
                ref="email"
                key="confirmation.email"
                v-model="confirmation.email"
                v-validate="'required|email'"
                :error="errors.first('email')"
                name="email"
                type="email"
                label="Sähköpostiosoite"
                placeholder="Sähköpostiosoite"
              />
            </div>
            <div class="column is-6">
              <form-input
                key="confirmation.puhelin"
                v-model="confirmation.puhelin"
                v-validate="'required'"
                :error="errors.first('puhelin')"
                name="puhelin"
                type="tel"
                label="Puhelinnumero"
                placeholder="esim. 040 123 4567"
                tooltip="Ilmoita puhelinnumerosi. Salasana omille sivuille toimitetaan tekstiviestillä."
              />
            </div>
          </div>
          <div class="columns">
            <div class="column has-items-end">
              <button :disabled="data.submitDisabled || data.submitLoading" class="button is-primary is-rounded is-3d" type="button" @click="submit">
                <span>Jatka tarjouksiin</span>
                <font-awesome-icon :icon="['fas', 'arrow-right']" style="vertical-align: middle; margin-left: .2em" />
                <span v-if="data.submitLoading" class="icon"><font-awesome-icon :icon="['fas', 'spinner-third']" spin />
                </span>
              </button>
            </div>
          </div>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import handleFieldError from '~/mixins/application/handleFieldError'
import thankYou from '~/mixins/application/thankYou'
import { euro } from '~/utils/form/masks'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formInput: () => import( '~/components/form/input' ),
    formSelect: () => import( '~/components/form/select' )
  },
  mixins: [
    handleFieldError,
    thankYou
  ],
  props: {
    confirmation: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    redirectUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      data: {
        submitDisabled: false,
        submitLoading: false
      },
      form: {
        action: 'confirmation'
      },
      masks: {
        euro
      }
    }
  },
  mounted () {
    this.$nextTick( () => {
      this.$el.scrollIntoView( {
        behavior: 'smooth',
        block: 'start'
      } )
    } )
  },
  methods: {
    async sendForm () {
      const data = Object.assign( this.form, this.confirmation )
      data.id = this.id
      let result = {}
      // Multiply income fields by 12 to get annual income.
      data.bruttotulot = parseInt( data.bruttotulot.replace( / /g, '' ) ) * 12
      data.nettotulot = parseInt( data.nettotulot.replace( / /g, '' ) ) * 12
      try {
        result = await this.$axios.$post( '/v1/?action=confirmation', data )
      }
      catch ( error ) {
        this.$logger.error( error )
      }
      if ( Object.prototype.hasOwnProperty.call( result, 'success' ) && result.success === true ) {
        this.$emit( 'sent', result )
        return true
      }
      else if ( Object.prototype.hasOwnProperty.call( result, 'errors' ) ) {
        this.handleFieldError( result.errors )
        return false
      }
      return false
    }
  }
}
</script>
