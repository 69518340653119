<template>
  <div class="customer box">
    <co-applicant-modal :active="showCoApplicantModal" @close="showCoApplicantModal = false" />
    <div class="box-title has-top-radius">
      <div class="columns">
        <div class="column">
          Omat tiedot
        </div>
      </div>
    </div>
    <div class="customer-content no-top-radius">
      <div class="columns">
        <div v-if="$store.state.customer.loanType === 'business'" class="column">
          <p class="title is-4">
            Yrityksen yhteystiedot
          </p>
          <ul>
            <li>{{ $store.state.customer.applicant.yritysnimi }} ({{ $store.state.customer.applicant.ytunnus }}) </li>
            <li>{{ $store.state.customer.applicant.business_address }}</li>
            <li>{{ $store.state.customer.applicant.business_postal }} {{ $store.state.customer.applicant.business_city }}</li>
          </ul>
        </div>
        <div class="column">
          <p class="title is-4">
            Hakijan yhteystiedot
          </p>
          <ul>
            <li>{{ $store.getters['customer/fullName'] }}</li>
            <li>{{ $store.state.customer.applicant.email }}</li>
            <li>{{ $store.state.customer.applicant.puhelin }}</li>
            <li>{{ $store.state.customer.applicant.osoite }}</li>
            <li>{{ $store.state.customer.applicant.postinro }} {{ $store.state.customer.applicant.kaupunki }}</li>
          </ul>
        </div>
        <div v-if="(coAllowed || $store.state.customer.coapplicant) && $store.state.customer.loanType === 'consumer'" class="column">
          <p class="title is-4">
            Yhteishakijan tiedot
          </p>
          <div v-if="coAllowed && !$store.state.customer.coapplicant">
            <button class="button is-primary is-rounded is-outlined" @click="showCoApplicantModal = !showCoApplicantModal">
              <span class="icon">
                <font-awesome-icon :icon="['fal', 'user-alt']" fixed-width />
              </span>
              <span class="has-text-weight-normal">
                Lisää yhteishakija
              </span>
            </button>
          </div>
          <ul v-if="$store.state.customer.coapplicant">
            <li>{{ $store.state.customer.coapplicant.yv_etunimi }} {{ $store.state.customer.coapplicant.yv_sukunimi }}</li>
            <li>{{ $store.state.customer.coapplicant.yv_email }}</li>
            <li>{{ $store.state.customer.coapplicant.yv_puhelin }}</li>
            <li>{{ $store.state.customer.coapplicant.yv_osoite }}</li>
            <li>{{ $store.state.customer.coapplicant.yv_postinro }} {{ $store.state.customer.applicant.yv_kaupunki }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    coApplicantModal: () => import( '~/components/login/customer/coApplicantModal' )
  },
  props: {
    coAllowed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showCoApplicantModal: false
    }
  }
}
</script>
