<script>
import banks from '~/components/layout/banks'

// Override banks data
banks.data = null

export default {
  name: 'BusinessBanks',
  extends: banks,
  data () {
    return {
      banks: {
        'Captial Box': 'capitalbox',
        Qred: 'qred',
        Yritysluotto: 'yritysluotto',
        Businesscredit: 'businesscredit',
        CrediNord: {
          raw: 'credinord',
          path: require( '~/static/partners/credinord.svg?external' )
        },
        Kasvurahoitus: 'kasvurahoitus',
        Yritysluottoflex: 'yritysluottoflex',
        Vauraus: {
          raw: 'vauraus',
          path: require( '~/static/partners/vauraus_transparent.png?external' )
        },
        Alisa: 'alisa',
        Fundu: 'fundu',
        Smefinance: {
          raw: 'smefinance',
          path: require( '~/static/partners/smefinance.svg?external' )
        },
        CapitalBoxYritysluotto: 'capitalboxflex'
      }
    }
  }
}
</script>
