export default {
  methods: {
    /**
     * Scrolls to interest rate with animation
     *
     * @param {refsName} String - Name of the ref to scroll to
     */
    scrollTo ( refsName ) {
      const element = this.$refs[refsName].$el
      element.scrollIntoView( {
        behavior: 'smooth',
        block: 'center'
      } )
      element.style.transition = 'all .3s'
      element.style.transform = 'scale(1.04)'
      setTimeout( () => {
        element.style.transform = ''
      }, 500 )
    }
  }
}
