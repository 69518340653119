/**
 * Euro mask
 *
 * @export
 * @param {string} value
 * @returns {regex}
 */
export function euro ( value ) {
  const string = String( value )
  const stripped = string.replace( /(?:\.|,).*/g, '' ).replace( /([^\d])/g, '' )
  let digits = stripped.length
  const result = []
  for ( let index = 0; index < digits; index++ ) {
    if ( index % 4 === 3 ) {
      result.unshift( ' ' )
      digits++
    }
    else {
      result.unshift( /\d/ )
    }
  }
  const end = [/(?:\.|,)/, /\d/, /\d/]
  result.push( ...end )
  return result
}
/**
 * SSN mask
 *
 * @export
 * @param {string} value
 * @returns {regex}
 */
export function ssn ( value ) {
  return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /-|A|a/, /\d/, /\d/, /\d/, /./]
}
