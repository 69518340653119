import _ from 'lodash'
import Incomplete from '~/utils/form/Incomplete'
import mergeFormData from '~/mixins/application/mergeFormData'

export default {
  data () {
    return {
      data: {
        incomplete: null,
        incompletePopUp: false
      },
      watchers: {
        form: {}
      }
    }
  },
  mixins: [mergeFormData],
  mounted () {
    // Merge vuex store to form data
    const vuexState = this.$store.state
    // Init Incomplete
    if (
      !_.isEmpty( vuexState.onload ) &&
      Object.prototype.hasOwnProperty.call( vuexState.onload, 'incomplete' )
    ) {
      const incomplete = vuexState.onload.incomplete
      if ( incomplete === true ) {
        this.data.incompletePopUp = true
      }
      if (
        _.isObject( incomplete ) &&
        Object.prototype.hasOwnProperty.call( incomplete, 'code' )
      ) {
        if ( incomplete.code === 204 ) {
          const errorMappings = {
            DisposableExpiredException: 'Keskeneräinen hakemus on vanhentunut',
            DisposableNotActiveException:
              'Keskeneräinen hakemus on täytetty loppuun',
            DisposableMissingException: 'Keskeneräinen hakemus on vanhentunut'
          }
          const error = errorMappings[incomplete.data]
            ? errorMappings[incomplete.data]
            : incomplete.data
          this.$noty( { type: 'error', text: error, timeout: false } )
        }
        if ( incomplete.code === 200 ) {
          this.onIncompleteClose( incomplete.data )
          this.$noty( {
            type: 'success',
            text: 'Keskeneräinen hakemus on esitäytetty sivulle',
            timeout: false
          } )
        }
        this.$store.dispatch( 'resetIncomplete' )
      }
    }
  },
  methods: {
    startIncomplete ( data = this.getData() ) {
      // Create instance when not already created
      if ( !( this.data.incomplete instanceof Incomplete ) ) {
        this.data.incomplete = new Incomplete( data )
      }
      // Create watched for each field
      Object.keys( this.form ).forEach( ( field ) => {
        this.watchers.form[field] = this.$watch(
          `form.${field}`,
          ( newValue, oldValue ) => {
            this.data.incomplete.change( field, newValue, oldValue )
          },
          { deep: true }
        )
      } )
    },
    /**
     * Sends incomplete application
     *
     * @param {string} [action='saveContact'] - Request action
     *
     * @retuns {boolean}
     */
    async sendIncomplete ( action = 'saveContact' ) {
      const data = this.getData()
      // Store incomplete application
      let result = {}
      try {
        result = await this.$axios.$post( `/v1/?action=${action}`, data )
      }
      catch ( error ) {
        this.$logger.error( error )
      }
      if (
        Object.prototype.hasOwnProperty.call( result, 'success' ) &&
        result.success === true
      ) {
        // Start listening incomplete changes
        if (
          Object.prototype.hasOwnProperty.call( result, 'incomplete' ) &&
          result.incomplete === true
        ) {
          this.startIncomplete( data )
        }
        this.$emit( 'incompleteSent' )
        this.$tracking.onIncompleteSent( this.form )
        return true
      }
      else if ( Object.prototype.hasOwnProperty.call( result, 'errors' ) ) {
        this.handleFieldError( result.errors )
        return false
      }
      this.data.applicationError = '<strong>Järjestelmävirhe</strong><p>Emme voineet vastaanottaa hakemustanne, koska tapahtui odottamaton järjestelmävirhe.</p>'
      return false
    },
    /**
     * Function will be called when incomplete popup is closed
     */
    async onIncompleteClose ( data ) {
      this.data.incompletePopUp = false
      if ( data ) {
        this.data.firstStep = true
        await this.$nextTick()
        await this.mergeFormData( data )
        await this.$nextTick()
        this.startIncomplete()
      }
    }
  }
}
