export default {
  methods: {
    /**
     * Handles field error on application
     *
     * @param {object} errors Object containing error with error message
     */
    handleFieldError ( errors ) {
      Object.keys( errors ).forEach( ( fieldName ) => {
        try {
          const field = this.$validator.fields.find( { name: fieldName } )
          field.setFlags( { invalid: true } )
          this.errors.add( {
            field: fieldName,
            msg: errors[fieldName].message || errors[fieldName],
            id: field.id,
            scope: field.scope
          } )
        }
        catch ( error ) {
          this.$logger.warning( error, {
            metaData: {
              field: fieldName,
              errorBag: errors
            }
          } )
        }
      } )
    }
  }
}
