<template>
  <div class="saved-applications app">
    <div class="columns saved-applications-top">
      <div class="column">
        Tallennetut hakemukset
      </div>
      <div class="column is-narrow" @click="getList">
        <font-awesome-icon :icon="['fas', 'sync-alt']" size="lg" :spin="data.loading" class="has-text-primary" />
      </div>
      <div class="column is-narrow">
        <button
          class="button is-rounded is-primary is-small"
          type="button"
          @click="openModal"
        >
          Tallenna
        </button>
      </div>
    </div>
    <div class="saved-applications-bottom">
      <div class="columns">
        <div class="column"><b>Title</b></div>
        <div class="column"><b>Description</b></div>
        <div class="column is-narrow">
          <font-awesome-icon :icon="['fal', 'trash-alt']" size="2x" fixed-width style="opacity: 0; cursor: default;" />
          <font-awesome-icon :icon="['fas', 'angle-right']" size="2x" fixed-width style="opacity: 0; cursor: default;" />
        </div>
      </div>
      <div
        v-for="(application, index) in applications"
        :key="index" class="columns"
        :class="{ 'has-background-white-ter': !(index % 2) }"
      >
        <div class="column">{{ application.name }}</div>
        <div class="column">{{ application.description }}</div>
        <div class="column is-narrow">
          <font-awesome-icon :icon="['fal', 'trash-alt']" size="2x" fixed-width @click="deleteApplication(application.id)" />
          <font-awesome-icon :icon="['fas', 'angle-right']" size="2x" fixed-width @click="fillApplication(application.id)" />
        </div>
      </div>
    </div>
    <!-- Store modal -->
    <div class="saved-applications-modal modal" :class="{'is-active': data.modalOpen }">
      <div class="modal-background" />
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Tallenna keskeneräinen hakemus</p>
          <button class="delete" aria-label="close" @click="data.modalOpen = false" />
        </header>
        <section class="modal-card-body">
          <form-input
            key="form.title"
            v-model.trim="form.title"
            v-validate="'required'"
            :error="errors.first('title')"
            name="title"
            type="text"
            placeholder="Otsikko"
          />
          <div class="field">
            <div class="control">
              <textarea
                key="desc"
                v-model="form.desc"
                v-validate="'required'"
                name="desc"
                class="textarea"
                :class="{ 'is-danger': errors.first('desc') }"
                placeholder="Lisätiedot hakemuksesta..."
                rows="4"
                data-vv-as="Lisätiedot hakemuksesta"
              />
            </div>
            <p v-if="errors.first('desc')" class="help is-danger">{{ errors.first('desc') }}</p>
          </div>
        </section>
        <footer class="modal-card-foot is-block">
          <button class="button is-white is-rounded" @click="data.modalOpen = false">Sulje</button>
          <button
            :disabled="data.submitLoading"
            class="button is-primary is-rounded"
            style="float: right;"
            type="button"
            @click="saveApplication"
          >
            <span>Lähetä</span>
            <span v-if="data.submitLoading" class="icon">
              <font-awesome-icon :icon="['fas', 'spinner-third']" spin />
            </span>
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { fieldFilter } from '~/utils/form/data'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formInput: () => import( '~/components/form/input' )
  },
  data () {
    return {
      data: {
        loading: false,
        modalOpen: false,
        submitLoading: false
      },
      form: {
        title: '',
        desc: ''
      },
      applications: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    /**
     * Get's list of stored applications from database
     */
    async getList () {
      this.data.loading = true
      const result = await this.$axios.$get( '/v1/cc/api/save' )
      this.applications = result
      this.data.loading = false
    },
    /**
     * Opens modal to fill tile and description for application
     */
    openModal () {
      if ( this.applicationVisible() ) {
        this.data.modalOpen = true
      }
    },
    /**
     * Check's if application is visible.
     * Shows notification otherwise
     */
    applicationVisible () {
      const result = Boolean( document.querySelector( '.loan-application.application' ) )
      // Send notification
      if ( !result ) {
        this.$noty( {
          layout: 'bottom',
          type: 'warning',
          text: 'Hakemusta ei ole näkyvissä.',
          timeout: 5000
        } )
      }
      return result
    },
    /**
     * Stores application into database.
     */
    async saveApplication () {
      this.data.submitLoading = true
      const validateResult = await this.$validator.validate()
      if ( !validateResult ) {
        this.data.submitLoading = false
        return
      }
      const application = document.getElementsByClassName( 'loan-application' )[0].__vue__
      const result = await this.$axios.$post( '/v1/cc/api/save', {
        fields: fieldFilter( Object.keys( application.fields ), application.form, true ),
        info: this.form
      } )

      if ( _.has( result, 'success' ) ) {
        this.form.title = ''
        this.form.desc = ''
        this.data.modalOpen = false
        this.$noty( {
          layout: 'bottom',
          type: 'success',
          text: 'Hakemus on tallennettu',
          timeout: 5000
        } )
        // Refresh list
        this.getList()
      }
      this.data.submitLoading = false
    },
    /**
     * Deletes the saved application from database.
     *
     * @param {string} id Application id
     */
    async deleteApplication ( id ) {
      await this.$axios.$get( '/v1/cc/api/save/del', {
        params: {
          id
        }
      } )
      // Refresh list
      this.getList()
    },
    /**
     * Get's application data and fills it to the page.
     *
     * @param {string} id Application id
     */
    async fillApplication ( id ) {
      // Check if application is visible.
      if ( !this.applicationVisible() ) {
        return
      }
      const result = await this.$axios.$get( '/v1/cc/api/save/fill', {
        params: {
          id
        }
      } )
      const fields = {}
      const application = document.getElementsByClassName( 'loan-application' )[0].__vue__
      // Re map fields to make correct name: value array
      Object.keys( result ).forEach( key => {
        fields[result[key].fieldName] = result[key].value
      } )
      // Clear the form first (if there would be some other data.)
      await application.clearForm()
      // Merge fields
      await application.mergeFormData( {
        ...fields,
        firstStep: true,
        ehdot: true,
        tarkistus: true
      } )
      // Notify about action
      this.$noty( {
        layout: 'bottom',
        type: 'success',
        text: 'Hakemus on täytetty sivulle.',
        timeout: 5000
      } )
    }
  }
}
</script>

<style lang="scss">
.saved-applications {
  &-top {
    border-bottom: 2px solid $grey-light;
    .column:not(:last-child) {
      border-right: 2px solid $grey-light;
    }
  }
  &-bottom {
    svg {
      padding: 0.2em;
      background-color: $primary;
      color: white;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-modal.modal {
    z-index: 99999;
  }
}
</style>
