



























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import type { PartnerLink } from '~/types/cms/partners'

export default defineComponent( {
  name: 'BankLinks',
  props: {
    visible: {
      required: true,
      type: Array as PropType<PartnerLink[]>,
      default: () => []
    }
  }
} )
