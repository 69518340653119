<template>
  <div
    class="decision"
    :class="{
      'is-chosen': chosen,
      'is-not-chosen': chosen === false && decision.state !== 4,
      'is-signed': eSignClicked
    }"
  >
    <div
      class="columns is-mobile is-multiline is-variable is-1 decision-details"
      @click="showInfo"
    >
      <div class="column is-6-mobile">
        <decision-detail>
          <div class="decision-details-logo">
            <img
              v-if="typeof getImg === 'string'"
              :key="name"
              :src="require(`~/static/partners/${getImg}.png`)"
              :data-srcset="require(`~/static/partners/${getImg}@2x.png`) + ' 2x'"
              :alt="name"
            >
            <img v-else :key="name" v-lazy="getImg.path" :alt="name">
          </div>
        </decision-detail>
      </div>
      <div
        class="column is-6-mobile is-hidden-tablet"
        style="display: flex; justify-content: center; align-items: center;"
      >
        <p style="text-decoration: underline;">
          {{ infoVisible ? 'Piilota lisätiedot' : 'Lisätietoja' }}
        </p>
      </div>
      <div v-if="infoVisible" class="column is-12-mobile is-hidden-tablet">
        <decision-info
          v-if="decision.state < 4"
          :finance="decision.info.raw"
          :button-style="2"
          :description="decision.info.descr"
          :info-visible="infoVisible"
          :show-button="chosen && getESignLink && !eSignClicked"
          :button-text="decision.name === 'yritysluotto'
            ? 'Viimestele lainatarjous tästä'
            : undefined"
          @hide="infoVisible = false"
          @click="handleSigning"
        />
      </div>
      <template v-if="decision.state === 1">
        <div class="column decision-details-status">
          Rahoitusyhtiö ei tehnyt tarjousta
        </div>
        <div class="column is-hidden-mobile">
          <!-- Dummy column to align properly -->
        </div>
      </template>
      <template v-else-if="decision.state === 2">
        <div
          v-if="name === 'nordax'
            && has(decision.data, 'elapsed')
            && decision.data.elapsed >= 4
          "
          class="column decision-details-status"
        >
          Nordax tarvitsee lisätietoja, olethan yhteydessä numeroon 030 603 6040
        </div>
        <div
          v-else-if="name === 'vauraus'"
          class="column decision-details-status"
          v-html="decision.info.descr"
        />
        <div v-else class="column decision-details-status">
          Hakemus on käsittelyssä {{ mask(decision.data.applied) }} eurolla.
        </div>
        <div class="column is-hidden-mobile">
          <!-- Dummy column to align properly -->
        </div>
      </template>
      <template v-else-if="decision.state === 3">
        <div class="column is-6-mobile">
          <decision-detail
            :value="`${mask(decision.data.amount)} €`"
            :subtitle="['komplettbank', 'yritysluottoflex', 'sveabusiness', 'ferratumbusinessflex'].includes(name) ?
              'Limiittiluotto' : 'Kertaluotto'"
          >
            <template #title>
              Laina<span
                class="is-inline-mobile-md is-hidden-mobile-lg"
                style="display: none;"
              >-<br></span>summa
            </template>
          </decision-detail>
        </div>
        <!-- Business application -->
        <template v-if="$store.state.customer.loanType === 'business'">
          <div class="column is-6-mobile">
            <decision-detail
              :value="['yritysluottoflex', 'sveabusiness', 'ferratumbusinessflex'].includes( decision.info.raw )
                ? 'Valittavissa'
                : `${ mask(Math.round(decision.data.period)) } kk`"
            >
              <template #title>
                Laina-aika
              </template>
            </decision-detail>
          </div>
          <div class="column is-6-mobile">
            <decision-detail
              :value="['yritysluottoflex', 'sveabusiness', 'ferratumbusinessflex'].includes( decision.info.raw )
                ? `Alk. ${ mask(Math.round(decision.data.monthly)) } €`
                : `${ mask(Math.round(decision.data.monthly)) } €`"
            >
              <template #title>
                Kuukausi<span
                  class="is-inline-mobile-md is-hidden-mobile-lg"
                  style="display: none;"
                >-<br></span>erä
              </template>
            </decision-detail>
          </div>
          <div class="column is-6-mobile">
            <decision-detail
              :value="['yritysluottoflex', 'sveabusiness', 'ferratumbusinessflex'].includes( decision.info.raw )
                ? 'Valittavissa'
                : getLoanFullCost"
              :value-star="getLoanFullCostStar"
            >
              <template #title>
                Kokonais<span
                  class="is-inline-mobile"
                  style="display: none;"
                >-<br></span>kustannukset (sis. lainasumman)
              </template>
            </decision-detail>
          </div>
        </template>
        <!-- Customer application -->
        <template v-else>
          <div class="column is-6-mobile">
            <decision-detail
              :value="`${ mask(Math.round(decision.data.monthly)) } €/kk`"
              :subtitle="`${decision.data.period} vuotta`"
              :value-star="false"
            >
              <template #title>
                Kuukausi<span
                  class="is-inline-mobile-md is-hidden-mobile-lg"
                  style="display: none;"
                >-<br></span>erä
              </template>
            </decision-detail>
          </div>
          <div class="column is-6-mobile">
            <decision-detail
              :value="['yritysluottoflex', 'sveabusiness', 'ferratumbusinessflex'].includes( decision.info.raw )
                ? 'Valittavissa'
                : getLoanFullCost"
              :value-star="['yritysluottoflex', 'sveabusiness', 'ferratumbusinessflex'].includes( decision.info.raw )
                ? false
                : getLoanFullCostStar"
            >
              <template #title>
                Kokonais<span
                  class="is-inline-mobile"
                  style="display: none;"
                >-<br></span>kustannukset (sis. lainasumman)
              </template>
            </decision-detail>
          </div>
          <div class="column is-6-mobile">
            <decision-detail
              :value="`${ parseFloat(decision.data.interest).toFixed(2) } %`"
            >
              <template #title>
                Nimellis<span
                  class="is-inline-mobile-md is-hidden-mobile-lg"
                  style="display: none;"
                >-<br></span>korko
              </template>
            </decision-detail>
          </div>
          <div class="column is-6-mobile">
            <decision-detail :value="getAPR">
              <template #title>Todellinen vuosikorko</template>
            </decision-detail>
          </div>
        </template>
        <div class="column is-6-mobile is-hidden-tablet">
          <decision-detail>
            <template #title>Tarvittavat lisätiedot</template>
            <ul
              v-if="attachments.length"
              class="is-size-6 is-size-7-tablet"
            >
              <li
                v-for="(attachment, key)
                  in attachments"
                :key="key"
              >
                {{ attachment }}.
              </li>
            </ul>
            <span v-else class="is-size-6 is-size-7-tablet">Ei lähetettäviä liitteitä.</span>
          </decision-detail>
        </div>
        <div class="column is-hidden-mobile">
          <decision-toggle
            :chosen="Boolean(chosen)"
            :e-sign-clicked="Boolean(eSignClicked)"
            @toggleOffer="toggleOffer"
            @signOffer="handleSigning"
          />
        </div>
        <!-- Additional information of the offer -->
        <div
          v-if="decision.info.raw === 'svea'"
          class="column is-12 decision-subtitle"
        >
          <div>Jopa 12 kk lyhennysvapaata laina-ajan alkuun.</div>
        </div>
        <div
          v-else-if="decision.info.raw === 'ferratumbusiness'"
          class="column is-12 decision-subtitle"
        >
          <div>Lainaan on mahdollista saada ensimmäiset 60 päivää maksuvapaata.</div>
        </div>
      </template>
      <template v-else-if="decision.state === 4">
        <div class="column decision-details-status">
          Valitsemasi lainan maksu on vahvistettu
          {{ mask(decision.data.amount) }} eurolla. Kiitos kilpailutuspalvelumme
          käyttämisestä.
        </div>
        <div class="column is-hidden-mobile">
          <!-- Dummy column to align properly -->
        </div>
      </template>
      <template v-else-if="decision.state === 5 || decision.state === 6">
        <div class="column decision-details-status">
          Tarjous ei ole enää voimassa. Ota tarvittaessa yhteyttä
          asiakaspalveluumme.
        </div>
        <div class="column is-hidden-mobile">
          <!-- Dummy column to align properly -->
        </div>
      </template>
    </div>
    <div v-if="chosen && !eSignClicked && decision.state < 4" class="columns">
      <div class="column">
        <div class="content decision-chosen has-text-centered-mobile">
          <template v-if="chosen && getESignLink">
            <template v-if="decision.name === 'yritysluotto'">
              <a
                class="is-primary-link is-hidden-mobile"
                :href="getESignLink"
                @click.prevent="handleSigning"
              >
                Viimeistele hakemuksesi Yritysluotto.fissä klikkaamalla tästä.
              </a>
            </template>
            <template v-else-if="decision.info.raw === 'sveabusiness'">
              <a
                class="is-primary-link is-hidden-mobile"
                :href="getESignLink"
                @click.prevent="handleSigning"
              >
                Voit viimeistellä hakemuksesi ja nostaa lainan tilillesi tästä.
              </a>
            </template>
            <template v-else>
              Valitsit {{ getFormOfOwnership(decision.info.display) }}
              lainatarjouksen.
              <a
                v-if="!['resursbank', 'komplettbank'].includes(decision.info.raw)"
                class="is-primary-link is-hidden-mobile"
                :href="getESignLink"
                @click.prevent="handleSigning"
              >{{ getESignText }}</a>
            </template>
          </template>
          <template
            v-else-if="decision.data.delivery ===
              'Lainatarjous on toimitettu sähköpostiisi'"
          >
            {{ decision.info.display }} on lähettänyt sähköpostiosoitteeseesi
            allekirjoituslinkin, jonka avulla voit nostaa lainan tilillesi.
          </template>
          <template v-else-if="decision.data.delivery === 'Puhelimitse'">
            Kiitos, {{ decision.info.display }} lainasi on pian valmis siirtoa
            varten. Sinuun ollaan kohta yhteydessä {{ decision.info.display }}
            asiakaspalvelusta.
          </template>
          <template
            v-else-if="decision.data.delivery === 'Käsin sähköpostitse'"
          >
            Kiitos, {{ decision.info.display }} lainasi on pian valmis siirtoa
            varten. Saat hetken kuluttua sähköpostia {{ decision.info.display }}
            asiakaspalvelusta.
          </template>
        </div>
      </div>
      <div class="column is-12-mobile is-hidden-tablet">
        <decision-info
          :info-visible="true"
          :finance="decision.info.raw"
          :process="decision.state === 3 ? decision.info.process : []"
          :show-button="chosen && getESignLink && !eSignClicked"
          :button-text="decision.name === 'yritysluotto' ? 'Viimestele lainatarjous tästä' : undefined"
          @hide="infoVisible = false"
          @click="handleSigning"
        >
          <template v-if="decision.info.raw === 'komplettbank' && decision.state === 3">
            <b>
              Limiittiluotossa lainan lyhennysmäärän ja laina-ajan voi määritellä itse. Kulut on laskettu
              tarjotun laina-ajan mukaan, tarkemmat tiedot näet lainasopimukselta.
            </b>
          </template>
          <template
            v-if="['yritysluottoflex'].includes( decision.info.raw ) &&
              decision.state === 3"
          >
            <b>
              Limiittimuotoisen luottotilin avaaminen on maksutonta. Flex-joustoluotossa ei ole kuukausimaksua tai
              muita kiinteitä kuluja, vaan kuukausittainen maksuerä koostuu ainoastaan kuukausikorosta sekä
              mahdollisesta nostopalkkiosta, mikäli luottoa nostetaan käyttöön.<br>
              Pääomalle lasketaan kuukausikorkoa 5 %. Nostoista peritään 3 % nostopalkkio nostoa seuraavan laskun
              yhteydessä. Luotto on lyhennysvapaa, jolloin voit itse päättää luoton lyhennystahdin, milloin tahansa
              maksaa kuukausierän lisäksi haluamasi suuruisia lyhennyksiä tai maksaa koko käyttämäsi luoton kerralla
              takaisin.
            </b>
          </template>
        </decision-info>
      </div>
    </div>
    <div v-if="chosen && eSignClicked && decision.state < 4" class="columns decision-clicked">
      <div class="column">
        <div class="content">
          <p>
            <span v-if="decision.info.raw === 'sveabusiness'">
              Olet valinnut {{ getFormOfOwnership(decision.info.display) }} ja siirtynyt viimeistelmään sitä. Mikäli
              sinulla on ongelmia {{ getFormOfOwnership(decision.info.display) }} -lainan hakemiseen liittyvissä
              asioissa, ole hyvä ja ota yhteyttä asiakaspalveluun {{ decision.info.contact || '09 2311 3670' }}.
            </span>
            <span v-else>
              Olet valinnut {{ getFormOfOwnership(decision.info.display) }} ja siirtynyt allekirjoittamaan sitä. Mikäli
              sinulla on ongelmia {{ getFormOfOwnership(decision.info.display) }} -lainan nostamiseen liittyvissä
              asioissa, ole hyvä ja ota yhteyttä asiakaspalveluun {{ decision.info.contact || '09 2311 3670' }}.
            </span>
            <a
              class="is-primary-link"
              :href="getESignLink"
              @click.prevent="handleSigning"
            >Jos haluat palata lainan allekirjoitukseen niin paina tästä.</a>
          </p>
          <div class="columns">
            <div class="column">
              <button class="button is-responsive" @click="logout">Sulje Oma Rahalaitos palvelu</button>
            </div>
            <div class="column">
              <button class="button is-responsive" @click="discardOffer">
                En ottanut lainaa, haluan palata lainatarjouksiin
              </button>
            </div>
            <div class="column is-hidden-tablet">
              <button class="button is-responsive" @click="handleSigning">
                Palaa allekirjoittamaan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <decision-info
      v-if="decision.state < 4"
      class="is-hidden-mobile"
      :finance="decision.info.raw"
      :attachments="attachments"
      :info-visible="infoVisible"
      :description="decision.info.descr"
      :process="decision.state === 3 ? decision.info.process : []"
      :show-button="chosen && getESignLink && !eSignClicked"
      :button-text="decision.name === 'yritysluotto' ? 'Viimestele lainatarjous tästä' : undefined"
      @hide="infoVisible = false"
      @click="handleSigning"
    >
      <template v-if="decision.info.raw === 'komplettbank' && decision.state === 3">
        <b>
          Limiittiluotossa lainan lyhennysmäärän ja laina-ajan voi määritellä itse. Kulut on laskettu
          tarjotun laina-ajan mukaan, tarkemmat tiedot näet lainasopimukselta.
        </b>
      </template>
      <template
        v-else-if="['yritysluottoflex'].includes( decision.info.raw ) &&
          decision.state === 3"
      >
        <b>
          Limiittimuotoisen luottotilin avaaminen on maksutonta. Flex-joustoluotossa ei ole kuukausimaksua tai
          muita kiinteitä kuluja, vaan kuukausittainen maksuerä koostuu ainoastaan kuukausikorosta sekä
          mahdollisesta nostopalkkiosta, mikäli luottoa nostetaan käyttöön.<br>
          Pääomalle lasketaan kuukausikorkoa 5 %. Nostoista peritään 3 % nostopalkkio nostoa seuraavan laskun
          yhteydessä. Luotto on lyhennysvapaa, jolloin voit itse päättää luoton lyhennystahdin, milloin tahansa
          maksaa kuukausierän lisäksi haluamasi suuruisia lyhennyksiä tai maksaa koko käyttämäsi luoton kerralla
          takaisin.
        </b>
      </template>
    </decision-info>
    <decision-toggle
      v-if="decision.state === 3 && !eSignClicked"
      class="is-hidden-tablet"
      :chosen="Boolean(chosen)"
      :e-sign-clicked="Boolean(eSignClicked)"
      @toggleOffer="toggleOffer"
      @signOffer="handleSigning"
    />
    <component
      :is="selectPopUpComponent"
      v-if="selectPopUp"
      @selected="chooseOffer(true)"
      @close="selectPopUp = false"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { conformToMask } from 'text-mask-core'
import { mapActions } from 'vuex'
import { getWidth } from '~/utils/window'
import { euro } from '~/utils/form/masks'

export default {
  components: {
    decisionDetail: () => import( '~/components/login/decisions/decisionDetail' ),
    decisionInfo: () => import( '~/components/login/decisions/decisionInfo' ),
    decisionToggle: () => import( '~/components/login/decisions/decisionToggle' )
  },
  props: {
    decision: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    setChoosen: {
      type: Boolean,
      default: null
    },
    setInfoVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chosen: this.setChoosen,
      infoVisible: this.setInfoVisible,
      selectPopUp: false,
      additional: _.has( this.decision, 'data.additional' )
        ? this.decision.data.additional
        : {}
    }
  },
  computed: {
    /**
     * Get's correct image path for finance companies
     */
    getImg () {
      const mappings = {
        collector: 'collectorbank',
        dfcnordic: 'laina.fi',
        ferratumbusiness: 'capitalbox',
        ferratumbusinessflex: 'capitalboxflex',
        halens: 'halino',
        monobank: 'leabank',
        balanzia: 'brocc',
        nordax: 'nordaxbank',
        tactfinance: 'saldo',
        norwegian: 'norwegian',
        komplettbank: {
          path: require( '~/static/partners/morrow.svg' )
        },
        fellowfinance: 'alisa',
        instabank: {
          path: require( '~/static/partners/instabank-2.svg' )
        },
        ferratum: {
          path: require( '~/static/partners/ferratum.svg' )
        },
        bondora: {
          path: require( '~/static/partners/bondora.svg' )
        },
        qred: {
          path: require( '~/static/partners/qred.svg' )
        },
        crediNord: {
          path: require( '~/static/partners/credinord.svg' )
        },
        smefinance: {
          path: require( '~/static/partners/smefinance.svg' )
        }
      }
      return this.name in mappings ? mappings[this.name] : this.name
    },
    /**
     * Get's correct esign link for finance company
     */
    getESignLink () {
      if ( _.has( this.additional, 'esigning_applicant.value' ) ) {
        let link = this.additional.esigning_applicant.value
        if ( link.indexOf( 'http://' ) !== 0 && link.indexOf( 'https://' ) !== 0 ) {
          link = `https://${link}`
        }
        return link
      }
      if ( this.name === 'ostosraha' || this.name === 'euroloan' ) {
        return `/${this.name}`
      }
      return undefined
    },
    getESignText () {
      let text = `Voit tutustua lainatarjoukseen ja nostaa lainan tilillesi
        tästä.`
      if ( this.name === 'tando' ) {
        text = `Voit allekirjoittaa lainatarjouksen ja täydentää takaajan tiedot
          tästä`
      }
      return text
    },
    /**
     * Stores last interaction
     */
    lastInteraction () {
      if ( _.has( this.decision, 'data.interactions' ) ) {
        return this.decision.data.interactions[0]
      }
      return undefined
    },
    /**
     * Is last interaction eSignClicked
     */
    eSignClicked () {
      return (
        this.lastInteraction && this.lastInteraction.name === 'esignClicked'
      )
    },
    selectPopUpComponent () {
      return () =>
        import( `~/components/login/decisions/selectPopUp/${this.name}` )
    },
    getLoanFullCost () {
      if ( _.has( this.additional, 'loanfullcost' ) ) {
        return `${this.mask(
          Math.round(
            parseFloat( this.additional.loanfullcost.value, 10 )
          )
        )} €`
      }
      else {
        return '-'
      }
    },
    getLoanFullCostStar () {
      if (
        this.$store.state.customer.loanType === 'consumer' &&
        _.has( this.additional, 'loanfullcost' )
      ) {
        return true
      }
      // When there is no fullcost we don't have to return any star
      else {
        return false
      }
    },
    getAPR () {
      if ( _.has( this.additional, 'apr' ) ) {
        const value = parseFloat(
          this.additional.apr.value
        ).toFixed( 2 )
        return `${value} ${this.additional.apr.unit}`
      }
      else {
        return '-'
      }
    },
    attachments () {
      const result = []
      if ( _.has( this.additional, 'attachments' ) ) {
        Object.keys( this.additional.attachments.value ).forEach( ( attachmentKey ) => {
          const attachment = this.additional.attachments.value[attachmentKey]
          const ignore = ['instantor', 'consolidation_invoice', 'co_payslip', 'other']
          if ( !ignore.includes( attachment.name ) ) {
            result.push( attachment.display_name )
          }
        } )
      }
      return result
    }
  },
  watch: {
    /**
     * Hide info when esing is clicked
     */
    eSignClicked ( newValue ) {
      if ( newValue ) {
        this.infoVisible = false
      }
    },
    setChoosen ( value ) {
      this.chosen = value
      if ( value && ['resursbank', 'komplettbank'].includes( this.decision.info.raw ) ) {
        this.setCountdown()
      }
      else {
        clearInterval( this.timer )
      }
    },
    setInfoVisible ( value ) {
      this.infoVisible = value
    },
    /**
     * Watch decision deeply so we can grab additional data when it changes.
     */
    decision: {
      handler ( value ) {
        this.additional = _.has( this.decision, 'data.additional' )
          ? this.decision.data.additional
          : {}
      },
      deep: true
    }
  },
  methods: {
    ...mapActions( 'auth', ['login', 'password', 'logout', 'resend'] ),
    /**
     * Allows straight access to lodash _.has
     */
    has: _.has,
    /**
     * Masks string to formatted euro
     */
    mask ( value ) {
      return conformToMask( String( value ), euro, { guide: false } )
        .conformedValue
    },
    /**
     * Shows info when allowed
     */
    showInfo () {
      // Don't show info on business when in handling state
      if (
        this.$store.state.customer.loanType === 'business' &&
        this.decision.state === 2
      ) {
        this.infoVisible = false
        return
      }
      // When offer is chosen but not signed you can't hide it's info on dekstop
      if ( getWidth() > 768 && this.chosen && !this.eSignClicked ) {
        this.infoVisible = true
        return
      }
      this.infoVisible = !this.infoVisible
    },
    /**
     * Toggles offer
     */
    toggleOffer () {
      if ( this.chosen ) {
        this.discardOffer()
      }
      else {
        this.chooseOffer()
      }
      clearInterval( this.timer )
    },
    /**
     * Chooses offer
     *
     * @param {boolean} immediately Should we skip additional information
     */
    chooseOffer ( immediately = false ) {
      // When forced to choose choose it
      if ( immediately ) {
        this.selectPopUp = false
        this.chosen = true
        // Show info on dekstop view only
        if ( getWidth() > 768 ) {
          this.infoVisible = true
        }
        this.$emit( 'chosen', this.name )
        this.storeInteraction( 'selected' )
      }
      // Force choose the offer
      else {
        this.chooseOffer( true )
      }
    },
    setCountdown () {
      const buttons = document.getElementsByClassName( 'decision-info-sign' )
      const notice = document.getElementsByClassName( 'esignlink-notice' )
      for ( const elm of notice ) {
        elm.innerHTML = 'Lainasopimusta muodostetaan. Odota hetki!'
      }
      let seconds = 6
      for ( const el of buttons ) {
        el.disabled = true
      }
      this.timer = setInterval( function () {
        seconds--
        if ( seconds <= 0 ) {
          for ( const el of buttons ) {
            el.disabled = false
          }
          for ( const elm of notice ) {
            elm.innerHTML = ''
          }
        }
      }, 1000 )
    },
    /**
     * Discards offer
     */
    discardOffer () {
      this.chosen = null
      this.infoVisible = false
      this.$emit( 'discarded', this.name )
      this.storeInteraction( 'unselected' )
    },
    /**
     * Formats string into right form of ownership
     * @param
     */
    getFormOfOwnership ( name ) {
      const len = name.length
      const lastChar = name[len - 1]
      const secondLast = name[len - 2]
      const thirdLast = name[len - 3]
      const vowels = ['a', 'e', 'i', 'o', 'u']
      const domains = ['fi', 'com', 'net', 'org', 'se', 'no']

      // If the company name is a URL.
      if ( domains.includes( name.split( '.' )[1] ) ) {
        return `${name}:n`
      }
      // If the company ends with kko, tto etc. remove one of the consonants.
      if ( secondLast === thirdLast ) {
        name = name.slice( 0, len - 2 ) + lastChar
      }
      if ( vowels.includes( lastChar.toLowerCase() ) ) {
        return `${name}n`
      }
      return `${name}in`
    },
    /**
     * Stores the interaction and opens eSign link into new tab
     */
    handleSigning () {
      this.storeInteraction( 'esignClicked' )
      window.open( this.getESignLink, '_blank' )
    },
    /**
     * Stores interaction to database
     * Also requests updated decision data
     */
    async storeInteraction ( type ) {
      try {
        await this.$axios.$post( `/v1/oma/?action=interaction&method=${type}`, {
          id: this.decision.data.id,
          finance: this.decision.info.raw
        } )
        this.$emit( 'needUpdate', true )
      }
      catch ( error ) {
        this.$logger.error( error )
      }
    }
  }
}
</script>

<style lang="scss">
.decision {
  display: flex;
  flex-direction: column;
  // min-height: 100px;
  position: relative;
  // padding: 1rem 0 0.25rem;

  &-subtitle {
    text-align: center;
    align-self: flex-end;

    div {
      @include tablet {
        background: darken(white, 7);
        background: linear-gradient(90deg, #fff 5%, #f2f2f2, #fff 95%);
      }
    }
  }
  &.is-chosen {
    background-color: darken(white, 5);

    .decision-subtitle div {
      background: darken(white, 5);
    }

    &:hover {
      background-color: darken(white, 7);

      .decision-subtitle div {
        background: darken(white, 7);
      }
    }
  }
  &.is-not-chosen {
    opacity: 0.2;
    pointer-events: none;
  }
  &:not(.is-signed):hover {
    background-color: darken(white, 5);
    cursor: pointer;

    .decision-subtitle div {
      background: darken(white, 5);
    }
  }
  @include tablet {
    &:not(:last-child) {
      border-bottom: 1px solid $grey-lighter;
    }
  }
  @include mobile {
    background-color: white;
    border-radius: $box-radius !important;
    margin: 0.75rem -1.5rem;
    padding: 0.25rem;
    &:first-child {
      margin-top: -0.75rem;
    }
  }
  @include desktop {
    &:first-child {
      margin-top: -1.5rem;
    }
  }
  .decision-chosen {
    font-weight: bold;
    padding: 0.75rem 0.75rem 0;
    cursor: auto;
  }
  .decision-details {
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    user-select: none;
    flex-grow: 1;

    @include mobile {
      >.column:nth-child(n + 3) {
        border-top: 1px solid $grey-lighter;
      }

      >.column:nth-child(2n + 4),
      >.column:nth-child(1) {
        border-right: 1px solid $grey-lighter;

      }
      >.column:nth-last-child(-n + 3) {
        border-bottom: 1px solid $grey-lighter;
      }
      flex-wrap: wrap;
      align-items: stretch;
    }
    @include tablet {
      > .column {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 .8em;
      padding: .5rem;

      img {
        width: auto;
        height: auto;
        max-height: 60px;
        max-width: 100%;
      }
    }

    &-status {
      flex-grow: 5;
      text-align: center;
    }
  }
  .decision-clicked,
  .decision-info {
    position: relative;
    padding: .75rem;
  }

  .decision-clicked {
    padding-bottom: .75rem;
  }

  .decision-info {
    @include mobile {
      .content ol {
        margin-top: 0;
        margin-left: 1.25rem;
      }
    }
  }
}
</style>
