<template>
  <div>
    <form class="app" method="post" action="?action=saveContact" @submit.prevent>
      <div class="columns">
        <div class="column">
          <p class="is-uppercase has-text-weight-bold title is-4">Lainahakemus</p>
          <p v-if="!$store.state.whitelabel" class="subtitle is-6">Lainan hakeminen on maksutonta eikä se sido sinua mihinkään.</p>
          <p v-else class="subtitle is-6">Lainan hakeminen ja vertaileminen on maksutonta eikä se sido sinua mihinkään.</p>
        </div>
        <div class="column is-narrow is-hidden-mobile">
          <img
            v-if="$store.state.whitelabel && !$store.state.whitelabel.iframe"
            :key="$store.state.whitelabel.site"
            v-lazy="require(`~/assets/whitelabels/sectigo_trust_seal.png`)"
            :alt="'Secure site'"
          >
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <loan-slider
            key="form.luottoraja"
            v-model.number="form.luottoraja"
            v-validate="'required'"
            name="luottoraja"
            @sliderEnd="updateGiosgValues(); (typeof $refs.loanConcat === 'object' && $refs.loanConcat.updateMaxValue()); calculateLoans()"
          />
        </div>
        <div class="column">
          <loan-slider
            key="form.maksuaika"
            v-model.number="form.maksuaika"
            v-validate="'required'"
            name="maksuaika"
            title="Laina-aika"
            :min="1"
            :max="15"
            :step="1"
            :format="(value) => parseInt(value, 10) + ' vuotta'"
            @sliderEnd="updateGiosgValues()"
          />
        </div>
      </div>
      <div class="app-section has-background-primary interest-rate py-4 px-5">
        <div class="has-text-centered">
          <span class="is-size-5 has-text-weight-bold has-text-white">
            Lainan kuukausierän arvio<sup style="cursor: pointer;" @click="scrollTo('interestRate')">*</sup>:
            <span class="is-size-4">{{ data.monthly }} €/kk</span>
          </span>
        </div>
      </div>
      <div class="columns">
        <div class="column is-bottom-paddingless-mobile">
          <form-checkbox
            key="form.yhdista_lainoja"
            v-model="form.yhdista_lainoja"
            v-validate="'required'"
            name="yhdista_lainoja"
            string-value="Kyllä"
          >
            Haluan yhdistää vanhoja lainoja
          </form-checkbox>
          <!-- Wrapper class for slider, slider uses 100% height -->
          <div>
            <loan-slider
              v-if="form.yhdista_lainoja"
              ref="loanConcat"
              key="form.poismaksu"
              v-model.number="form.poismaksu"
              name="poismaksu"
              :min="100"
              :max="form.luottoraja"
              :start="form.luottoraja"
              title="Aion maksaa vanhoja lainoja pois"
            />
          </div>
        </div>
        <div class="column is-top-paddingless-mobile">
          <form-checkbox
            key="form.yv_hakija"
            v-model="form.yv_hakija"
            v-validate="'required'"
            name="yv_hakija"
            string-value="Kyllä"
          >
            Haen yhteishakijan kanssa
            <p slot="help" class="is-size-7" style="margin-left: 2rem;">
              (Yhteishakijan kanssa voit saada paremman lainatarjouksen)
            </p>
          </form-checkbox>
        </div>
      </div>
      <div class="app-section app-section_header">
        <p class="is-uppercase has-text-weight-bold subtitle is-6">Yleistiedot</p>
        <div class="columns is-multiline">
          <div class="column is-4 no-padding-bottom">
            <form-input
              ref="sotu"
              key="form.sotu"
              v-model="form.sotu"
              v-validate="'required|finnish_ssn|age_min:20|age_max:79'"
              v-uppercase
              :error="errors.first('sotu')"
              name="sotu"
              label="Henkilötunnus"
              placeholder="XXXXXX-XXXX"
              tooltip="Henkilötunnus ilmoitetaan muodossa: ppkkvv-loppuosa, esim. 123456-123A."
              @change="
                data.applicantAge = calculateAge(form.sotu)
                data.applicantGender = calculateGender(form.sotu)
              "
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-email v-model="form.email" />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="form.puhelin"
              v-model="form.puhelin"
              v-validate="'required|phone_stripped'"
              :error="errors.first('puhelin')"
              name="puhelin"
              type="tel"
              label="Puhelinnumero"
              placeholder="esim. 040 123 4567"
              tooltip="Ilmoita puhelinnumerosi. Salasana omille sivuille toimitetaan tekstiviestillä."
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="form.etunimi"
              v-model="form.etunimi"
              v-validate="'required|min:2|max:40'"
              :error="errors.first('etunimi')"
              name="etunimi"
              label="Etunimi"
              placeholder="Etunimi"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="form.sukunimi"
              v-model="form.sukunimi"
              v-validate="'required|min:2|max:40'"
              :error="errors.first('sukunimi')"
              name="sukunimi"
              label="Sukunimi"
              placeholder="Sukunimi"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-select
              key="form.tyosuhde"
              v-model="form.tyosuhde"
              v-validate="'required'"
              :error="errors.first('tyosuhde')"
              :disable="data.unemployedError"
              name="tyosuhde"
              label="Työsuhde"
              placeholder="Työsuhde"
            >
              <option value="Vakituinen">Vakinainen</option>
              <option value="Määräaikainen">Määräaikainen</option>
              <option value="Projektityöntekijä">Projektityöntekijä</option>
              <option value="Yrittäjä">Yrittäjä</option>
              <option value="Työtön">Työtön</option>
              <option value="Eläkeläinen">Eläkeläinen</option>
              <option value="Opiskelija">Opiskelija</option>
            </form-select>
          </div>
          <template v-if="form.tyosuhde !== 'Työtön' && form.tyosuhde !== 'Opiskelija' && form.tyosuhde">
            <div class="column is-4 no-padding-bottom">
              <form-input
                ref="bruttotulot"
                key="form.bruttotulot"
                v-model="form.bruttotulot"
                v-validate="'required|numeric_spaces|more_than:nettotulot'"
                :error="errors.first('bruttotulot')"
                :mask="{ mask: masks.euro, guide: false }"
                :icon="['far', 'euro-sign']"
                name="bruttotulot"
                type="tel"
                label="Bruttotulot kuukaudessa"
                placeholder="Bruttotulot kuukaudessa"
                tooltip="Ilmoita kuukausitulosi ennen veroja."
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                ref="nettotulot"
                key="form.nettotulot"
                v-model="form.nettotulot"
                v-validate="{
                  required: true,
                  numeric_spaces: true,
                  min_value_stripped: 600,
                  percentage_difference: {
                    target: form.bruttotulot,
                    percentage: 39
                  },
                  less_than: 'bruttotulot'
                }"
                :error="errors.first('nettotulot')"
                :mask="{ mask: masks.euro, guide: false }"
                :icon="['far', 'euro-sign']"
                name="nettotulot"
                type="tel"
                label="Nettotulot kuukaudessa"
                placeholder="Nettotulot kuukaudessa"
                tooltip="Ilmoita kuukausitulosi verojen jälkeen. Nettokuukausitulojen täytyy olla vähintään 600 euroa."
              />
            </div>
          </template>
        </div>
        <template v-if="data.firstStep">
          <div class="columns app-handling-notification">
            <div class="column">
              <p class="notification is-primary is-size-6" style="padding: 0.75rem; border-radius: 5px;">
                Hakemuksesi on tallennettu. Täydennä puuttuvat tiedot ja kilpailuta lainat nopeasti.
              </p>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.osoite"
                v-model="form.osoite"
                v-validate="'required|max:128'"
                :error="errors.first('osoite')"
                name="osoite"
                label="Katuosoite"
                placeholder="Katuosoite"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-postal v-model="form.postinro" />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.kaupunki"
                v-model="form.kaupunki"
                v-validate="'required|alpha_spaces|min:2|max:128'"
                :error="errors.first('kaupunki')"
                name="kaupunki"
                label="Postitoimipaikka"
                placeholder="Postitoimipaikka"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="form.tili"
                v-model="form.tili"
                v-validate="'required'"
                :error="errors.first('tili')"
                name="tili"
                label="Tilinumero"
                placeholder="Tilinumero"
                tooltip="Ilmoita tilinumerosi, jonne haluat lainan siirrettävän."
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.siviilisaaty"
                v-model="form.siviilisaaty"
                v-validate="'required'"
                :error="errors.first('siviilisaaty')"
                name="siviilisaaty"
                label="Siviilisääty"
                placeholder="Siviilisääty"
              >
                <option value="Naimisissa">Naimisissa</option>
                <option value="Avoliitto">Avoliitto</option>
                <option value="Naimaton">Naimaton</option>
                <option value="Eronnut">Eronnut</option>
                <option value="Leski">Leski</option>
              </form-select>
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.lapset"
                v-model="form.lapset"
                v-validate="'required'"
                :error="errors.first('lapset')"
                name="lapset"
                label="Lapsia taloudessa"
                placeholder="Lapsia taloudessa"
                tooltip="Ilmoita alaikäisten lasten määrä taloudessa."
              >
                <option value="0">Ei yhtään</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">Enemmän kuin 6</option>
              </form-select>
            </div>
            <div
              v-if="data.applicantAge < 30 && data.applicantGender === 'M'"
              class="column is-4 no-padding-bottom"
            >
              <form-select
                key="form.asevelvollisuus"
                v-model="form.asevelvollisuus"
                v-validate="'required'"
                :error="errors.first('asevelvollisuus')"
                name="asevelvollisuus"
                label="Asevelvollisuus"
                placeholder="Asevelvollisuus"
              >
                <option value="Suoritettu">Suoritettu</option>
                <option value="Ei">Ei suoritettu</option>
                <option value="Suoritettu">Vapautettu</option>
              </form-select>
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.aryhma"
                v-model="form.aryhma"
                v-validate="'required'"
                :error="errors.first('aryhma')"
                name="aryhma"
                label="Ammattiryhmä"
                placeholder="Ammattiryhmä"
              >
                <option value="Maatalousyrittäjä">Maatalousyrittäjä</option>
                <option value="Yrittäjä">Yrittäjä</option>
                <option value="Ylempi toimihenkilö">Ylempi toimihenkilö</option>
                <option value="Alempi toimihenkilö">Alempi toimihenkilö</option>
                <option value="Työntekijä">Työntekijä</option>
                <option value="Eläkeläinen">Eläkeläinen</option>
                <option value="Muu">Muu</option>
              </form-select>
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.koulutus"
                v-model="form.koulutus"
                v-validate="'required'"
                :error="errors.first('koulutus')"
                name="koulutus"
                label="Koulutus"
                placeholder="Koulutus"
              >
                <option value="peruskoulu">Peruskoulu</option>
                <option value="lukio">Lukio</option>
                <option value="ammattikoulu">Ammattikoulu</option>
                <option value="Alempi korkeakoulututkinto">Alempi korkeakoulututkinto</option>
                <option value="Ylempi korkeakoulututkinto">Ylempi korkeakoulututkinto</option>
              </form-select>
            </div>
            <template v-if="form.tyosuhde === 'Yrittäjä'">
              <div class="column is-4 no-padding-bottom">
                <form-input
                  key="form.yritysnimi"
                  v-model="form.yritysnimi"
                  v-validate="'required'"
                  :error="errors.first('yritysnimi')"
                  name="yritysnimi"
                  label="Yrityksen nimi"
                  placeholder="Yrityksen nimi"
                />
              </div>
              <div class="column is-4 no-padding-bottom">
                <form-industry v-model="form.toimiala" />
              </div>
              <div class="column is-4 no-padding-bottom">
                <form-input
                  key="form.ytunnus"
                  v-model="form.ytunnus"
                  v-validate="'required|business'"
                  :error="errors.first('ytunnus')"
                  name="ytunnus"
                  label="Y-tunnus"
                  placeholder="Y-tunnus"
                />
              </div>
              <div class="column is-4 no-padding-bottom">
                <datepicker
                  key="form.rekpaiva"
                  v-model="form.rekpaiva"
                  v-validate="'required'"
                  :error="errors.first('rekpaiva')"
                  format="dd.MM.yyyy"
                  minimum-view="month"
                  maximum-view="year"
                  initial-view="year"
                  name="rekpaiva"
                  label="Yrityksen rekisteröintipäivämäärä"
                  placeholder="Yrityksen rekisteröintipäivämäärä"
                />
              </div>
            </template>
            <template v-if="form.tyosuhde === 'Eläkeläinen'">
              <div class="column is-4 no-padding-bottom">
                <form-input
                  key="form.tyonantaja"
                  v-model="form.tyonantaja"
                  v-validate="'required|min:3'"
                  :error="errors.first('tyonantaja')"
                  name="tyonantaja"
                  label="Eläkelaitos"
                  placeholder="Eläkelaitos"
                />
              </div>
              <div class="column is-4 no-padding-bottom">
                <datepicker
                  key="form.tyonalkupvm"
                  v-model="form.tyonalkupvm"
                  v-validate="'required'"
                  :error="errors.first('tyonalkupvm')"
                  minimum-view="month"
                  maximum-view="year"
                  initial-view="year"
                  name="tyonalkupvm"
                  label="Eläkkeen alkamispäivämäärä"
                  placeholder="Eläkkeen alkamispäivämäärä"
                />
              </div>
            </template>
            <template v-if="form.tyosuhde === 'Vakituinen' || form.tyosuhde === 'Määräaikainen' || form.tyosuhde === 'Projektityöntekijä'">
              <div class="column is-4 no-padding-bottom">
                <datepicker
                  key="form.tyonalkupvm"
                  v-model="form.tyonalkupvm"
                  v-validate="'required'"
                  :error="errors.first('tyonalkupvm')"
                  minimum-view="month"
                  maximum-view="year"
                  initial-view="year"
                  name="tyonalkupvm"
                  label="Työsuhde alkanut"
                  placeholder="Työsuhde alkanut"
                />
              </div>
              <div class="column is-4 no-padding-bottom">
                <form-input
                  key="form.tyonantaja"
                  v-model="form.tyonantaja"
                  v-validate="'required|max:128'"
                  :error="errors.first('tyonantaja')"
                  name="tyonantaja"
                  label="Työnantaja"
                  placeholder="Työnantaja"
                />
              </div>
              <div class="column is-4 no-padding-bottom">
                <form-industry v-model="form.toimiala" />
              </div>
              <div class="column is-4 no-padding-bottom">
                <form-input
                  key="form.ammatti"
                  v-model="form.ammatti"
                  v-validate="'required|max:128'"
                  :error="errors.first('ammatti')"
                  name="ammatti"
                  label="Ammatti"
                  placeholder="Ammatti"
                />
              </div>
            </template>
            <div v-if="form.tyosuhde === 'Määräaikainen' || form.tyosuhde === 'Projektityöntekijä'" class="column is-4 no-padding-bottom">
              <datepicker
                key="form.maaraaikapvm"
                v-model="form.maaraaikapvm"
                v-validate="'required'"
                :error="errors.first('maaraaikapvm')"
                :disabled-dates="{ to: new Date() }"
                minimum-view="month"
                maximum-view="year"
                initial-view="year"
                name="maaraaikapvm"
                label="Määräaikainen työsuhde loppuu"
                placeholder="Määräaikainen työsuhde loppuu"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.koti"
                v-model="form.koti"
                v-validate="'required'"
                :error="errors.first('koti')"
                name="koti"
                label="Talouden tyyppi"
                placeholder="Talouden tyyppi"
              >
                <option value="1">Yhden hengen talous tai yksinhuoltaja</option>
                <option value="2">Kaksi aikuista ja lapsi/lapsia</option>
                <option value="3">Muu (kaksi aikuista ei lapsia yms.)</option>
              </form-select>
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.bruttoHouse"
                v-model="form.bruttoHouse"
                v-validate="'required'"
                :error="errors.first('bruttoHouse')"
                name="bruttoHouse"
                label="Talouden kokonaistulot bruttona"
                placeholder="Talouden kokonaistulot bruttona"
                tooltip="Ilmoita talouden yhteenlasketut bruttovuositulot."
              >
                <option value="1">alle 24 000 €</option>
                <option value="2">24 000 - 30 000 €</option>
                <option value="3">30 000 - 37 000 €</option>
                <option value="4">37 000 - 48 500 €</option>
                <option value="5">yli 48 500 €</option>
              </form-select>
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                ref="elinkustannukset"
                key="form.elinkustannukset"
                v-model="form.elinkustannukset"
                v-validate="{
                  required: true,
                  numeric_spaces: true,
                }"
                :error="errors.first('elinkustannukset')"
                :mask="{ mask: masks.euro, guide: false }"
                :icon="['far', 'euro-sign']"
                name="elinkustannukset"
                type="tel"
                label="Elinkustannukset kuukaudessa"
                placeholder="Elinkustannukset kuukaudessa"
                tooltip="Kuinka suuret ovat kuukausittaiset kiinteät kustannuksesi? Huomioi tassä kentassä, kuinka paljon käytät mm. ruokaan. vaatteisiin. harrastuksiin. vakuutuksiin. sähköön, tervevdenhuoltoon ja liikkumiseen."
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.asumismuoto"
                v-model="form.asumismuoto"
                v-validate="'required'"
                :error="errors.first('asumismuoto')"
                name="asumismuoto"
                label="Asumismuoto"
                placeholder="Asumismuoto"
              >
                <option value="Oma">Oma</option>
                <option value="Asumisoikeus">Asumisoikeus</option>
                <option value="Asumisoikeus">Osaomistus</option>
                <option value="Vuokra">Vuokra</option>
                <option value="Vanhempien luona">Vanhempien luona</option>
              </form-select>
            </div>
            <template v-if="form.asumismuoto">
              <div class="column is-4 no-padding-bottom">
                <form-select
                  key="form.asunto"
                  v-model="form.asunto"
                  v-validate="'required'"
                  :error="errors.first('asunto')"
                  name="asunto"
                  label="Asunnon tyyppi"
                  placeholder="Asunnon tyyppi"
                >
                  <option value="Kerrostalo">Kerrostalo</option>
                  <option value="Rivitalo">Rivitalo</option>
                  <option value="Omakotitalo">Omakotitalo</option>
                  <option value="Muu">Muu</option>
                </form-select>
              </div>
              <div class="column is-4 no-padding-bottom">
                <datepicker
                  key="form.muuttopvm"
                  v-model="form.muuttopvm"
                  v-validate="'required'"
                  :error="errors.first('muuttopvm')"
                  minimum-view="month"
                  maximum-view="year"
                  initial-view="year"
                  name="muuttopvm"
                  label="Muuttoajankohta asuntoon"
                  placeholder="Muuttoajankohta asuntoon"
                  header="Muuttoajankohta asuntoon"
                />
              </div>
            </template>
            <div
              v-if="form.asumismuoto && !(form.asumismuoto === 'Oma' && form.asunto === 'Omakotitalo')"
              class="column is-4 no-padding-bottom"
            >
              <form-input
                key="form.vuokra"
                v-model="form.vuokra"
                v-validate="'required|numeric'"
                :error="errors.first('vuokra')"
                name="vuokra"
                :label="`${housingCompensation ? 'Vastike' : 'Vuokramenot'} /kk`"
                :placeholder="`${housingCompensation ? 'Vastike' : 'Vuokramenot'} /kk`"
                :tooltip="`${housingCompensation ? 'Vastike' : 'Vuokramenot'} /kk (vain oma osuus)`"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.kesamokki"
                v-model="form.kesamokki"
                v-validate="'required'"
                :error="errors.first('kesamokki')"
                name="kesamokki"
                label="Loma-asunto"
                placeholder="Loma-asunto"
              >
                <option value="1">Kyllä</option>
                <option value="0">Ei</option>
              </form-select>
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-select
                key="form.tarkoitus"
                v-model="form.tarkoitus"
                v-validate="'required'"
                :error="errors.first('tarkoitus')"
                name="tarkoitus"
                placeholder="Lainan tarkoitus"
                label="Lainan tarkoitus"
              >
                <option value="1">Aiempien lainojen yhdistäminen</option>
                <option value="2">Lomamatka</option>
                <option value="3">Remontti</option>
                <option value="4">Auton hankinta</option>
                <option value="5">Yllättävät menot</option>
                <option value="6">Muu tarkoitus</option>
              </form-select>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4 no-padding-bottom">
              <p class="has-no-wrap-desktop">Onko sinulla lainoja / luottokortteja</p>
              <form-select
                key="form.lainoja"
                v-model="form.lainoja"
                v-validate="'required'"
                :error="errors.first('lainoja')"
                name="lainoja"
                placeholder="Valitse"
              >
                <option value="Kyllä">Kyllä</option>
                <option value="Ei">Ei</option>
              </form-select>
            </div>
          </div>
        </template>
      </div>
      <div v-if="form.lainoja === 'Kyllä' && data.firstStep" class="app-section">
        <p class="is-uppercase has-text-weight-bold subtitle is-6">Luottokortit</p>
        <div class="columns is-multiline">
          <div class="column is-full-mobile is-4-mobile-lg no-padding-bottom">
            <form-switch
              key="form.visa"
              v-model="form.visa"
              v-validate="'required'"
              :error="errors.first('visa')"
              :bool="false"
              name="visa"
              label="Visa"
            />
          </div>
          <div class="column is-full-mobile is-4-mobile-lg no-padding-bottom">
            <form-switch
              key="form.master"
              v-model="form.master"
              v-validate="'required'"
              :error="errors.first('master')"
              :bool="false"
              name="master"
              label="Mastercard"
            />
          </div>
          <div class="column is-full-mobile is-4-mobile-lg no-padding-bottom">
            <form-switch
              key="form.muukortti"
              v-model="form.muukortti"
              v-validate="'required'"
              :error="errors.first('muukortti')"
              :bool="false"
              name="muukortti"
              label="Muu luottokortti"
            />
          </div>
        </div>
      </div>
      <div v-if="form.lainoja === 'Kyllä' && data.firstStep" class="app-section">
        <p class="is-uppercase has-text-weight-bold subtitle is-6">Lainat</p>
        <p v-if="form.yhdista_lainoja" class="is-size-7" style="margin-bottom: 10px">Muista merkitä lainasi ja mitä lainoja haluat yhdistää</p>
        <loan-picker
          key="form.Loans.MainApplicant"
          v-model="form.Loans.MainApplicant"
          :count="form.lainatlkm"
          name="lainatlkm"
          prefix="MainApplicant"
          @countChange="form.lainatlkm = $event; calculateLoans()"
          @input="calculateLoans()"
        />
        <p v-if="data.refinanceAlert" class="help is-danger">
          Hakemasi lainasumma on pienempi kuin yhdistettäväksi merkityt lainat. Muokkaa lainasummaa tai yhdistettävien lainojen summaa.
        </p>
      </div>
      <co-applicant v-if="form.yv_hakija && data.firstStep" />
      <div v-if="data.unemployedError" class="app-section">
        <div class="notification is-danger">
          Valitettavasti lainaa hakeakseen hakijalla tulee olla säännölliset <strong>palkka- tai eläketulot</strong>.
        </div>
      </div>
      <div class="app-section">
        <form-checkbox
          key="form.ehdot"
          v-model="form.ehdot"
          v-validate="'required:true'"
          :error="errors.first('ehdot')"
          data-vv-as="Ehdot"
          name="ehdot"
        >
          <a href="/yleiset-ehdot" class="has-text-dark is-underline" target="_blank">Hyväksyn yleiset ehdot (Lainatuotteet)</a>
          <template v-if="$store.state.whitelabel && $store.state.whitelabel.hasServiceDescription">
            sekä
            <a href="/palvelukuvaus" class="has-text-dark is-underline" target="_blank">Palvelukuvauksen</a>
          </template>
        </form-checkbox>
        <form-checkbox
          key="form.tarkistus"
          v-model="form.tarkistus"
          v-validate="'required:true'"
          :error="errors.first('tarkistus')"
          data-vv-as="Suostumus"
          name="tarkistus"
        >
          <a href="/kyselyjarjestelma"
             class="has-text-dark is-underline"
             target="_blank"
          >
            Suostumus tietojen luovutukseen
          </a>
        </form-checkbox>
        <form-checkbox
          key="form.marketing"
          v-model="form.marketing"
          v-validate="'required'"
          :error="errors.first('marketing')"
          data-vv-as="Markkinointi"
          name="marketing"
        >
          Kyllä kiitos, haluan vastaanottaa tarjouksia ja
          tietoa <span v-if="!$store.state.whitelabel">Rahalaitoksen</span>
          palvelusta ja eduista sähköpostilla ja tekstiviestillä.
        </form-checkbox>
        <p class="field">
          Olen tietoinen siitä, että luotonantaja voi kerätä ja käsitellä Verohallinnon Tulorekisteriyksikön
          ylläpitämästä positiivisesta luottorekisteristä saatavia luotto- ja tulotietojani. Luotonantaja voi käyttää
          näitä tietoja lainahakemuksen käsittelyyn, luottokelpoisuuden arviointiin sekä tietojen ajan tasalla olemisen
          tarkistamiseen. Luotonantaja voi myös ilmoittaa luottotietoni positiiviseen luottorekisteriin.
        </p>
        <button
          :disabled="data.submitDisabled || data.submitLoading"
          class="button is-primary is-rounded is-3d is-fullwidth"
          type="button"
          @click="submit"
        >
          <span>
            {{ data.firstStep ? 'Kilpailuta lainat': 'Jatka' }}
            <font-awesome-icon :icon="['fas', 'arrow-right']" style="vertical-align: middle; margin-left: .2em" />
          </span>
          <span v-if="data.submitLoading" class="icon">
            <font-awesome-icon :icon="['fas', 'spinner-third']" spin />
          </span>
        </button>
      </div>
      <div v-if="data.applicationError" class="app-section">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="notification is-danger" v-html="data.applicationError" />
      </div>
      <div class="app-section">
        <interest-rate ref="interestRate" class="is-size-7 is-italic has-text-weight-light" :loan-amount="form.luottoraja" :loan-period="form.maksuaika" @monthlyChanged="data.monthly = $event" />
        <br>
        <p v-if="$store.state.whitelabel">
          Välitämme lainahakemuksesi eteenpäin Rahalaitos.fi -kumppanillemme.
          Kotimainen Rahalaitos hoitaa hakemusprosessin kokonaisuudessaan.
          Rahalaitos on sinuun yhteydessä heti hakemuksen lähetettyäsi.
        </p>
      </div>
    </form>
    <incomplete-pop-up v-if="data.incompletePopUp" :active="data.incompletePopUp" @close="onIncompleteClose" />
  </div>
</template>

<script>
import _ from 'lodash'
import calculateAge from '~/mixins/calculateAge'
import coApplicantParent from '~/mixins/coApplicantParent'
import defaults from '~/mixins/application/defaults'
import handleFieldError from '~/mixins/application/handleFieldError'
import incomplete from '~/mixins/application/incomplete'
import mergeFormData from '~/mixins/application/mergeFormData'
import scrollTo from '~/mixins/action/scrollTo'
import updateGiosgValues from '~/mixins/application/updateGiosgValues'
import { euro } from '~/utils/form/masks'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    coApplicant: () => import( '~/components/loanApplication/coapplicant' ),
    datepicker: () => import( '~/components/form/datepicker' ),
    formCheckbox: () => import( '~/components/form/checkbox' ),
    formEmail: () => import( '~/components/form/email' ),
    formIndustry: () => import( '~/components/form/industry' ),
    formInput: () => import( '~/components/form/input' ),
    formPostal: () => import( '~/components/form/postal' ),
    formSelect: () => import( '~/components/form/select' ),
    formSwitch: () => import( '~/components/form/switch' ),
    incompletePopUp: () =>
      import( '~/components/loanApplication/incompletePopUp' ),
    interestRate: () => import( '~/components/form/interestRate' ),
    loanPicker: () => import( '~/components/form/loanPicker' ),
    loanSlider: () => import( '~/components/form/loanSlider' )
  },
  mixins: [
    calculateAge,
    coApplicantParent,
    defaults,
    handleFieldError,
    incomplete,
    mergeFormData,
    scrollTo,
    updateGiosgValues
  ],
  data () {
    return {
      data: {
        applicantAge: null,
        applicantGender: null,
        firstStep: this.applicationOpen,
        incomplete: null,
        submitDisabled: false,
        submitLoading: false,
        unemployedError: false,
        applicationError: null,
        monthly: 210, // Default value (will be changed when slider moves)
        incompletePopUp: false,
        refinanceAlert: false,
        incompleteLainoja: false
      },
      watchers: {
        checkUnemployment: null,
        form: {}
      },
      form: {
        luottoraja: 15000,
        maksuaika: 8,
        yhdista_lainoja: false,
        poismaksu: null,
        yv_hakija: false,
        sotu: '',
        email: '',
        puhelin: '',
        etunimi: '',
        sukunimi: '',
        tyosuhde: '',
        bruttotulot: '',
        nettotulot: '',
        ehdot: false,
        tarkistus: false,
        marketing: false,
        osoite: '',
        postinro: '',
        kaupunki: '',
        tili: '',
        siviilisaaty: '',
        lapset: '',
        aryhma: '',
        koulutus: '',
        yritysnimi: '',
        ytunnus: '',
        rekpaiva: '',
        elakelaitos: '',
        elakealkupvm: '',
        tyonalkupvm: null,
        ammatti: '',
        tyonantaja: '',
        toimiala: '',
        koti: '',
        bruttoHouse: '',
        elinkustannukset: '',
        asumismuoto: '',
        asunto: '',
        muuttopvm: '',
        vuokra: '',
        kesamokki: '',
        lainoja: '',
        visa: null,
        master: null,
        muukortti: null,
        lainatlkm: 0,
        Loans: {
          MainApplicant: []
        }
      },
      masks: {
        euro
      }
    }
  },
  computed: {
    housingCompensation () {
      return this.form.asumismuoto === 'Oma' && this.form.asunto !== 'Omakotitalo'
    },
    // check if starts to fill application
    isFormDirty () {
      return Object.keys( this.fields ).some( ( key ) => {
        if ( ['luottoraja', 'maksuaika'].includes( key ) ) {
          return false
        }
        return this.fields[key].dirty
      } )
    }
  },
  watch: {
    isFormDirty () {
      // google event if starts to fill application
      this.$ga.event( {
        eventCategory: 'loanApplication',
        eventAction: 'started'
      } )
      this.$gtag.event( 'started', {
        event_category: 'consumerloanApplication',
        value: this.form.luottoraja
      } )
    }
  },
  methods: {
    calculateLoans () {
      let refinanceSum = 0
      for ( let i = 0; i < parseInt( this.form.lainatlkm ); i++ ) {
        const loan = this.form.Loans.MainApplicant[i]
        if ( _.has( loan, 'refinance' ) && loan.loanType !== 'Mortgage' && loan.refinance === true ) {
          refinanceSum += parseInt( loan.totalAmount )
        }
      }
      this.data.refinanceAlert = ( refinanceSum > this.form.luottoraja )
    },
    checkUnemployment () {
      if ( this.form.tyosuhde === 'Opiskelija' || this.form.tyosuhde === 'Työtön' ) {
        // Check if we should redirect whitelabel
        if (
          _.has( this.$store.state, 'whitelabel.unemployedRedirect' ) &&
          this.$store.state.whitelabel.unemployedRedirect
        ) {
          window.location = this.$store.state.whitelabel.unemployedRedirect
        }
        this.data.unemployedError = true
        this.data.submitDisabled = true
        return true
      }
      this.data.unemployedError = false
      this.data.submitDisabled = false
      return false
    },
    /**
     * Handles form submit
     */
    async submit () {
      this.data.submitLoading = true
      const validateResult = await this.$validator.validate()
      if ( !validateResult ) {
        this.data.applicationError = 'Hakemusta ei voida lähettää ennen kuin seuraavat kentät on korjattu.<br><br>'
        // Add error fields to applicationError variable.
        for ( let i = 0; i < this.$validator.errors.items.length; i++ ) {
          const error = this.$validator.errors.items[i]
          this.data.applicationError += `${error.msg}<br>`
        }
        try {
          this.$el.querySelector( `[name="${this.$validator.errors.items[0].field}"]` ).scrollIntoView()
        }
        catch ( error ) {
          this.$logger.error( error, {
            metaData: {
              'Validator errors': this.$validator.errors
            }
          } )
        }
        this.data.submitLoading = false
        return
      }
      this.clearErrors()
      // Use correct sending type
      if ( this.data.firstStep ) {
        if ( await this.sendApplication() ) {
          this.data.submitDisabled = true
        }
      }
      else {
        // Check unemployment status
        if ( this.checkUnemployment() ) {
          return
        }
        // Send incomplete application
        if ( await this.sendIncomplete() ) {
          // Start watching unemployment status
          this.watchers.checkUnemployment = this.$watch( 'form.tyosuhde', this.checkUnemployment )
          // Set first step to done
          this.data.firstStep = true
        }
      }
      this.data.submitLoading = false
    }
  }
}
</script>
