<template>
  <div class="columns is-mobile is-centered">
    <div class="column is-9-mobile-md is-8-mobile-lg is-6-tablet is-5-desktop is-4-widescreen">
      <div class="content app">
        <div v-if="$store.state.auth.status === false || $store.state.auth.status == 9001">
          <p class="title is-5 has-text-centered">
            Tervetuloa tutustumaan lainatarjouksiin
          </p>
          <p class="subtitle is-6 has-text-centered">
            Näet lainatarjoukset syöttämällä henkilötunnuksesi, jonka jälkeen lähetämme sähköpostiisi ja matkapuhelimeesi salasanan.
          </p>
          <form-input
            key="form.sotu"
            v-model="form.sotu"
            v-validate="'required|finnish_ssn|age_min:20|age_max:79'"
            v-uppercase
            :enter-action="submitLogin"
            :error="errors.first('sotu')"
            :mask="{ mask: masks.ssn, guide: false }"
            name="sotu"
            type="text"
            placeholder="Henkilötunnus"
            input-class="is-large has-text-centered"
          />
          <button class="button is-rounded is-primary is-fullwidth" @click="submitLogin">
            Jatka
          </button>
          <article v-if="$store.state.auth.status === 9001" class="message is-danger" style="margin-top: 1em;">
            <div class="message-body">
              Etkö pääse kirjautumaan sisään ja tarkastelemaan lainahakemustasi? Mikäli olemme olleet sinuun yhteydessä
              tekstiviestitse tai sähköpostitse, ole hyvä ja kirjaudu sisään viestistä löytyvän linkin kautta.
            </div>
          </article>
        </div>
        <div v-else-if="$store.state.auth.status == 1 || $store.state.auth.status == 4 || $store.state.auth.status == 5 || $store.state.auth.status == 9002">
          <p class="title is-5 has-text-centered">
            Anna salasana
          </p>
          <p v-if="$store.state.auth.result.Expired" class="subtitle is-6 has-text-centered">
            Kirjoita salasana jonka lähetimme sinulle juuri sähköpostitse, sekä tekstiviestitse puhelinnumeroonne joka päättyy: +358******{{ phoneEnd }}.
          </p>
          <p v-else class="subtitle is-6 has-text-centered">
            Kirjoita aikaisemmin sähköpostitse, sekä tekstiviestitse puhelinnumeroonne +358******{{ phoneEnd }} lähettämämme salasana.
          </p>
          <div class="field">
            <div class="control">
              <form-input
                key="form.password"
                v-model="form.password"
                v-validate="'required'"
                v-uppercase
                :enter-action="submitPassword"
                :error="errors.first('password')"
                name="password"
                input-class="is-large has-text-centered"
                type="text"
                placeholder="Salasana"
              />
            </div>
          </div>
          <div class="columns is-marginless is-mobile">
            <div class="column is-paddingless">
              <button class="button is-rounded" @click="logout">
                Takaisin
              </button>
            </div>
            <div class="column is-narrow has-items-end is-paddingless">
              <button class="button is-rounded is-primary" @click="submitPassword">
                Jatka
              </button>
            </div>
          </div>
          <a class="has-text-centered is-block" @click="resend">
            Tilaa uusi salasana
          </a>
          <article v-if="$store.state.auth.status === 9002" class="message is-danger" style="margin-top: 1em;">
            <div class="message-body">
              Salasana ei kelpaa: tarkista, että olet kirjoittanut salasanan oikein ja yritä uudelleen.
            </div>
          </article>
          <article
            v-if="$store.state.auth.status === 4 || $store.state.auth.status === 5"
            class="message is-success"
            style="margin-top: 1em;"
          >
            <div class="message-body">
              Uusi salasana on lähetetetty matkapuhelimeesi ja sähköpostiisi. Huomiothan, että salasanan saapumisessa saattaa olla viivettä.
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ssn } from '~/utils/form/masks'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formInput: () => import( '~/components/form/input' )
  },
  data () {
    return {
      form: {
        sotu: '',
        password: ''
      },
      masks: {
        ssn
      }
    }
  },
  computed: {
    phoneEnd () {
      if ( this.$store.state.auth.result.PhoneNumber ) {
        return this.$store.state.auth.result.PhoneNumber
      }
      else if ( this.$store.state.customer.applicant.puhelin ) {
        return this.$store.state.customer.applicant.puhelin.slice( -3 )
      }
      else {
        return 'XXX'
      }
    }
  },
  methods: {
    ...mapActions( 'auth', [
      'login',
      'password',
      'logout',
      'resend'
    ] ),
    submitLogin () {
      this.$validator.validate().then( result => {
        if ( !result ) {
          return false
        }
        this.login( this.form.sotu )
      } )
    },
    submitPassword () {
      this.$validator.validate().then( result => {
        if ( !result ) {
          return false
        }
        this.password( this.form.password )
      } )
    }
  }
}
</script>
