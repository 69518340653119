import _ from 'lodash'

export default {
  data () {
    return {
      form: {
        yv_asuu: '',
        yv_sotu: '',
        yv_etunimi: '',
        yv_sukunimi: '',
        yv_osoite: '',
        yv_postinro: '',
        yv_kaupunki: '',
        yv_puhelin: '',
        yv_email: '',
        yv_siviilisaaty: '',
        yv_tyosuhde: '',
        yv_koulutus: '',
        yv_tyonalkupvm: '',
        yv_tyonantaja: '',
        yv_yritysnimi: '',
        yv_ytunnus: '',
        yv_rekpaiva: '',
        yv_elakealkupvm: '',
        yv_elakelaitos: '',
        yv_maaraaikapvm: '',
        yv_bruttotulot: '',
        yv_nettotulot: '',
        // yv_elinkustannukset: '',
        yv_lainoja: '',
        yv_lainatlkm: 0,
        Loans: {
          CoApplicant: []
        }
      },
      watchers: {
        coSameAddress: null
      }
    }
  },
  methods: {
    /**
     * Toggles watches for address fields when coSameAddress is chosen
     */
    coSameAddress ( value ) {
      if ( value === 'Kyllä' ) {
        // When there is coapplicant information in state copy them into form
        if (
          _.has( this.$store.state.customer.applicant, 'osoite' ) &&
          _.has( this.$store.state.customer.applicant, 'postinro' ) &&
          _.has( this.$store.state.customer.applicant, 'kaupunki' )
        ) {
          this.form.yv_osoite = this.$store.state.customer.applicant.osoite
          this.form.yv_postinro = this.$store.state.customer.applicant.postinro
          this.form.yv_kaupunki = this.$store.state.customer.applicant.kaupunki
        }
        // Otherwise add watched to watch main application comments
        else {
          this.watchers.coSameAddress = this.$watch(
            () => [this.form.osoite, this.form.postinro, this.form.kaupunki],
            () => {
              this.form.yv_osoite = this.form.osoite
              this.form.yv_postinro = this.form.postinro
              this.form.yv_kaupunki = this.form.kaupunki
            },
            { immediate: true }
          )
        }
      }
      else {
        if ( typeof this.watchers.coSameAddress === 'function' ) {
          this.watchers.coSameAddress()
        }
      }
    }
  }
}
