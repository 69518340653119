<template>
  <div :class="{ 'is-active': active }" class="modal is-large co-applicant-modal">
    <div class="modal-background" @click="close" />
    <div class="modal-card" style="width: 100%;">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Yhteishakijan tiedot
        </p>
        <button class="delete" aria-label="close" @click="close" />
      </header>
      <section class="modal-card-body">
        <co-applicant />
        <div v-if="data.applicationError" class="app-section">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="notification is-danger" v-html="data.applicationError" />
        </div>
      </section>
      <footer class="modal-card-foot is-block">
        <div class="columns">
          <div class="column">
            <button class="button is-rounded" @click="close">
              Sulje
            </button>
          </div>
          <div class="column is-narrow is-right">
            <button class="button is-primary is-rounded" @click="submit">
              Tallenna
            </button>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import coApplicantParent from '~/mixins/coApplicantParent'
import handleFieldError from '~/mixins/application/handleFieldError'

export default {
  name: 'CoApplicantModal',
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    coApplicant: () => import( '~/components/loanApplication/coapplicant' )
  },
  mixins: [coApplicantParent, handleFieldError],
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: {
        submitLoading: false,
        applicationError: ''
      },
      form: {
        osoite: this.$store.state.customer.applicant.osoite,
        postinro: this.$store.state.customer.applicant.postinro,
        kaupunki: this.$store.state.customer.applicant.kaupunki,
        yv_hakija: 'Kyllä'
      }
    }
  },
  methods: {
    close () {
      this.$emit( 'close' )
    },
    submit () {
      this.data.submitLoading = true
      this.$validator.validate().then( result => {
        if ( !result ) {
          this.data.applicationError = 'Hakemusta ei voida lähettää ennen kuin puuttuvat kentät on korjattu.'
          try {
            this.$el.querySelector( `[name="${this.$validator.errors.items[0].field}"]` ).scrollIntoView()
          }
          catch ( error ) {
            this.$logger.error( error, {
              metaData: {
                'Validator errors': this.$validator.errors
              }
            } )
          }
          this.data.submitLoading = false
          return false
        }
        this.sendApplication().then( () => {
          this.data.submitLoading = false
          return true
        } )
      } )
    },
    /**
     * Sends full application
     */
    async sendApplication () {
      let result = {}
      const data = { ...this.form }
      // Store as yearly income
      data.yv_bruttotulot = parseInt( data.yv_bruttotulot.replace( / /g, '' ) ) * 12
      data.yv_nettotulot = parseInt( data.yv_nettotulot.replace( / /g, '' ) ) * 12
      try {
        result = await this.$axios.$post( '/v1/oma/?action=coapp&method=add', data )
      }
      catch ( error ) {
        this.$logger.error( error )
      }
      if ( Object.prototype.hasOwnProperty.call( result, 'success' ) && result.success === true ) {
        this.$ga.event( {
          eventCategory: 'Oma',
          eventAction: 'Added',
          eventLabel: 'coApplicant'
        } )
        this.$gtag.event( 'event', 'Added', {
          event_category: 'Oma',
          event_label: 'coApplicant'
        } )
        await this.$store.dispatch( 'customer/setCoApplicant', result.coapplicant )
        this.close()
        return true
      }
      else if ( Object.prototype.hasOwnProperty.call( result, 'errors' ) ) {
        this.handleFieldError( result.errors )
        return false
      }
      this.$logger.info( { name: 'ValidationRequestError', message: 'No data' }, { metaData: result } )
      this.data.applicationError = '<strong>Järjestelmävirhe</strong><p>Emme voineet vastaanottaa hakemustanne, koska tapahtui odottamaton järjestelmävirhe.</p>'
      return false
    }
  }
}
</script>
