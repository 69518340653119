






















import { defineComponent } from '@nuxtjs/composition-api'
import partners from '~/utils/partners'

export default defineComponent( {
  name: 'Banks',
  data () {
    return {
      banks: partners
    }
  }
} )
