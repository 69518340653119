<template>
  <section class="hero main-hero">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="content" style="background-color: white; padding: 3rem; border-radius: 10px;">
              <h1>Tervehdys,</h1>
              <p>
                Olet aikeissa poistua postituslistaltamme. Markkinointiviesteissä kerromme kumppaneidemme tarjoamista
                eduista ja tarjouksista.
              </p>
              <p>Ystävällisin terveisin,<br>Rahalaitos.fi</p>
              <form v-if="!success" method="post" action="/v1/?action=markkinointikielto" class="mt-5" @submit.prevent>
                <div class="columns is-vcentered">
                  <div class="column">
                    <form-input
                      key="form.phone"
                      v-model="form.phone"
                      v-validate="{
                        required: form.email.length == 0,
                        phone_stripped: true,
                      }"
                      :error="errors.first('phone')"
                      name="phone"
                      label="Puhelinnumero"
                    />
                  </div>
                  <div class="column is-narrow">
                    ja/tai
                  </div>
                  <div class="column">
                    <form-input
                      key="form.email"
                      v-model="form.email"
                      v-validate="{
                        required: form.phone.length == 0,
                        email: true,
                      }"
                      :error="errors.first('email')"
                      name="email"
                      label="Sähköpostiosoite"
                    />
                  </div>
                </div>
                <div class="columns is-multiline">
                  <div class="column is-6-desktop">
                    <div class="field">
                      <p class="control">
                        <button
                          class="button is-rounded is-fullwidth"
                          type="button"
                          @click="$router.push({ path: '/' })"
                        >
                          Palaa etusivulle
                        </button>
                      </p>
                    </div>
                  </div>
                  <div class="column is-12-tablet is-6-desktop">
                    <div class="field">
                      <p class="control">
                        <button
                          class="button is-primary is-rounded is-fullwidth"
                          type="button"
                          @click="submit"
                        >
                          Poistu listaltamme
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-if="data.applicationError" class="notification is-danger" v-html="data.applicationError" />
              </form>
              <p
                v-else
                class="is-size-4 has-text-weight-bold has-text-rlgreen"
              >
                Antamasi osoite on poistettu postituslistalta. Sinut ohjataan etusivulle hetken kuluttua...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import formInput from '~/components/form/input'
import handleFieldError from '~/mixins/application/handleFieldError'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formInput
  },
  mixins: [handleFieldError],
  data () {
    return {
      success: false,
      data: {
        submitLoading: false,
        submitDisabled: false,
        applicationError: ''
      },
      form: {
        email: '',
        phone: ''
      }
    }
  },
  methods: {
    submit () {
      this.data.submitLoading = true
      this.$validator.validate().then( ( result ) => {
        this.data.submitLoading = false
        if ( !result ) {
          this.data.applicationError = 'Täytä joko puhelinnumero tai sähköpostiosoite.'
          try {
            this.$el.querySelector( `[name="${this.$validator.errors.items[0].field}"]` ).scrollIntoView()
          }
          catch ( error ) {
            this.$logger.error( error, {
              metaData: {
                'Validator errors': this.$validator.errors
              }
            } )
          }
          return
        }
        this.data.applicationError = ''
        this.sendRequest()
      } )
    },
    async sendRequest () {
      let result = {}
      try {
        result = await this.$axios.$post( '/v1/?action=markkinointikielto', {
          email: this.form.email ? this.form.email : null,
          phone: this.form.phone ? this.form.phone : null
        } )
      }
      catch ( error ) {
        this.$logger.error( error )
      }
      if ( Object.prototype.hasOwnProperty.call( result, 'errors' ) ) {
        this.$nextTick( () => {
          this.handleFieldError( result.errors )
        } )
        return false
      }
      if ( Object.prototype.hasOwnProperty.call( result, 'success' ) ||
        Object.prototype.hasOwnProperty.call( result, 'notEmpty' )
      ) {
        this.success = true
        this.$ga.event( { eventCategory: 'Markkinointikielto', eventAction: 'product' } )
        this.$gtag.event( 'ban', { event_category: 'marketing', event_label: 'product' } )
        // Move customer to front page
        window.setTimeout( () => {
          this.$router.push( { path: '/' } )
        }, 5000 )
        return true
      }
      this.data.applicationError = `
        <strong>Järjestelmävirhe</strong>
        <p>Emme voineet vastaanottaa pyyntöänne, koska tapahtui odottamaton järjestelmävirhe.</p>
        <p>
          Pahoittelemme tapahtunutta. Yritä myöhemmin uudelleen.
          Mikäli ongelma toistuu edelleen, ota yhteyttä asiakaspalveluumme.
        </p>
      `
    }
  }
}
</script>
