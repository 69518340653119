import _ from 'lodash'
import { parse } from 'qs'

export default {
  data () {
    return {
      form: {}
    }
  },
  mounted () {
    // Merge vuex store to form data
    this.mergeFromVuex()
    // Get slider values from query parameters
    const query = this.$route.query
    if ( query.lainasumma ) {
      const lainasumma = parseInt( query.lainasumma, 10 )
      if ( !isNaN( lainasumma ) ) {
        this.form.luottoraja = parseInt( query.lainasumma, 10 )
      }
    }
    // Exception for RL_autot arrive
    if ( query.arrive === 'RL_autot' ) {
      this.form.maksuaika = 15
    }
    else if ( query.lainaaika ) {
      const lainaaika = parseInt( query.lainaaika, 10 )
      if ( !isNaN( lainaaika ) ) {
        this.form.maksuaika = parseInt( query.lainaaika, 10 )
      }
    }
  },
  methods: {
    /**
     * Merges form data from vuex store.
     *
     */
    mergeFromVuex () {
      const vuexState = this.$store.state
      const vuexForm = vuexState.loanApplication.form
      if ( !_.isEmpty( vuexForm ) ) {
        this.mergeFormData( vuexForm ).then( () => {
          // Clear vuex merge
          this.$store.dispatch( 'loanApplication/clear' )
        } )
      }
    },
    /**
     * Merges data to form data
     * @params {object} data From data in format { etunimi: 'Value' }
     *
     * Notice you can pass additional `firstStep` value to open the form
     */
    async mergeFormData ( data ) {
      // Pause validator
      this.$validator.pause()
      await this.$nextTick()
      const clonedData = parse( data )
      Object.keys( clonedData ).forEach( ( field ) => {
        if ( Object.prototype.hasOwnProperty.call( this.form, field ) ) {
          const toNumber = ['luottoraja', 'maksuaika', 'poismaksu', 'lainatlkm', 'yv_lainatlkm']
          const toBoolean = ['yhdista_lainoja', 'yv_hakija', 'ehdot', 'tarkistus', 'marketing']
          // Format to number if in array
          if ( toNumber.includes( field ) ) {
            // Delete when not number and covert to int when it is
            if ( isNaN( clonedData[field] ) ) {
              delete clonedData[field]
            }
            else {
              clonedData[field] = parseInt( clonedData[field], 10 )
            }
          }
          // Format to boolean if in array
          else if ( toBoolean.includes( field ) ) {
            // Is field falsely
            if (
              clonedData[field] === 'false' ||
              clonedData[field] === '0' ||
              !clonedData[field] ||
              clonedData[field] === 'Ei' ||
              clonedData[field] === ''
            ) {
              clonedData[field] = false
            }
            // Make it true
            else {
              // Dadaa its true
              clonedData[field] = true
            }
          }
          // Map the form if not undefined
          if ( clonedData[field] !== undefined ) {
            this.form[field] = clonedData[field]
          }
          // Calculate age after filling
          if ( field === 'sotu' ) {
            this.data.applicantAge = this.calculateAge( this.form[field] )
          }
        }
      } )
      // Should we open fist step of the application
      if ( clonedData.firstStep ) {
        this.data.firstStep = true
      }
      await this.$nextTick()
      this.$validator.resume()
    },
    /**
     * Clears form data to default ones.
     *
     */
    async clearForm () {
      this.$validator.pause()
      await this.$nextTick()
      Object.assign( this.$data, this.$options.data.apply( this ) )
      await this.$nextTick()
      this.$validator.resume()
    }
  }
}
