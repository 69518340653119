/**
 * This mixin is used for loan application thank you and confirmation pages
 */
export default {
  computed: {
    isIframe () {
      return this.$store.state.whitelabel.iframe
    },
    isCRM () {
      return this.$route.query.source === 'cc_extranet'
    },
    showID () {
      return this.$route.query.showID !== undefined
    }
  },
  methods: {
    /**
     * Handles form submit logic
     */
    submit () {
      this.data.submitLoading = true
      this.$validator.validate().then( ( result ) => {
        if ( !result ) {
          this.data.submitLoading = false
          return
        }
        this.sendForm().then( ( result ) => {
          this.data.submitLoading = false
          if ( result ) {
            this.data.submitDisabled = true
            // When redirect URL is set redirect the applicant
            if ( this.redirectUrl ) {
              window.location.replace( this.redirectUrl )
            }
            else if ( this.$store.state.whitelabel ) {
              if ( this.isIframe ) {
                if ( this.isCRM ) {
                  window.location.reload()
                }
                else {
                  window.open( `https://${this.$domain}/oma`, '_blank' )
                }
              }
              else {
                window.location = `https://${this.$domain}/oma`
              }
            }
            else if (
              this.$route.query.arrive &&
              ['RL_mgkesken', 'RL_kesken'].includes( this.$route.query.arrive )
            ) {
              this.$router.push( 'application-stored' )
            }
            else if ( this.$route.path.startsWith( '/cc-new' ) ) {
              this.$router.push( '/cc-new' )
            }
            else {
              this.$router.push( 'oma' )
            }
          }
        } )
      } )
    }
  }
}
