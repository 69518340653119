import _ from 'lodash'
import axios from 'axios'
/**
 * Incomplete
 *
 * Used for sending Incomplete application via Ajax
 * @author Alan Aasmaa <alan.aasmaa@eone.fi>
 * @export
 * @class Incomplete
 */
export default class Incomplete {
  /**
   * List of fields that wont be sent
   *
   * @readonly
   * @memberof Incomplete
   */
  get discard () {
    return [
      'ehdot',
      'tarkistus'
    ]
  }

  /**
   * List of fields that will not be debounced
   *
   * @readonly
   * @memberof Incomplete
   */
  get sendImmediately () {
    return [
      'sotu',
      'email',
      'puhelin'
    ]
  }

  /**
   * Creates an instance of Incomplete.
   *
   * @param {object} data
   * @memberof Incomplete
   */
  constructor ( data ) {
    // List of application fields { old: value, new: value }
    this.application = {}
    // List of items that are waiting for an sending
    this.waitingSend = {}
    // Holds debounce functions for fields
    this.debounce = {
      fields: {},
      send: null
    }
    // Holds forceSend data
    this.forceSend = null
    // Initialize Incomplete with current data
    this.init( data )
  }

  /**
   * Initializes Incomplete with current data
   *
   * @param {object} data
   * @memberof Incomplete
   */
  init ( data ) {
    Object.keys( data ).forEach( ( field ) => {
      // When field is not in discard array
      if ( !this.discard.includes( field ) ) {
        this.store( field, data[field], true )
      }
    } )
  }

  /**
   * Notifies Incomplete about the change
   *
   * @param {object} fieldValue
   * @memberof Incomplete
   */
  change ( fieldName, fieldValue ) {
    // When field is discard array return
    if ( this.discard.includes( fieldName ) ) {
      return
    }
    // Return when values are same or when old and new are falsly except for onoff
    const oldValue = this.application[fieldName] ? this.application[fieldName].value : ''
    if ( ( oldValue === fieldValue ) || ( !oldValue && !fieldValue ) ) {
      return
    }
    // Create debounce function when undefined
    if ( typeof this.debounce.fields[fieldName] === 'undefined' ) {
      this.debounce.fields[fieldName] = _.debounce( ( fieldValue ) => {
        this.store( fieldName, fieldValue )
      }, 500 )
    }
    // Run debounce
    this.debounce.fields[fieldName]( fieldValue )
  }

  /**
   * Stores the field to Incomplete and starts sending process
   *
   * @param {string} fieldName
   * @param {any} fieldValue
   * @param {boolean} asSent
   * @memberof Incomplete
   */
  store ( fieldName, fieldValue, asSent = false ) {
    // Create new application field object when not existed
    if ( !this.application[fieldName] ) {
      this.application[fieldName] = {}
    }
    // Add value to sent queue
    if ( !asSent ) {
      this.waitingSend[fieldName] = fieldValue
    }
    // Add values like they are allready sent
    if ( asSent ) {
      this.application[fieldName].value = fieldValue
      this.application[fieldName].old = fieldValue
      // Return to not continue with sending
      return
    }
    // If fieldName is in in `sendImmediately` list sent it
    if ( this.sendImmediately.includes( fieldName ) ) {
      // Cancel debounce when available
      if ( this.debounce.send ) {
        this.debounce.send.cancel()
      }
      this.send()
      return
    }
    // Start sending
    this.sendBounce()
  }

  /**
   * Sends fields to server
   *
   * @memberof Incomplete
   */
  send () {
    // Clear forceSend timeout
    this.clearForceTimer()
    // Do not send when there is nothing to send
    if ( !Object.keys( this.waitingSend ).length ) {
      return
    }
    axios.post( '/v1/?action=updateContact', { fields: this.waitingSend }, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    } )
      .then( ( response ) => {
        const data = response.data
        if ( Object.prototype.hasOwnProperty.call( data, 'code' ) && data.code === 200 ) {
          if ( Object.prototype.hasOwnProperty.call( data.data, 'updated' ) ) {
            // Remove accepted items from waitingSend array
            data.data.updated.forEach( ( field ) => {
              this.application[field] = {
                old: this.application[field] ? this.application[field].value : null,
                value: this.waitingSend[field]
              }
              delete this.waitingSend[field]
            } )
          }
          if ( Object.prototype.hasOwnProperty.call( data.data, 'invalid' ) ) {
            // Remove accepted items from waitingSend array
            data.data.invalid.forEach( ( field ) => {
              delete this.waitingSend[field]
            } )
          }
        }
      } )
  }

  /**
   * Starts 3000ms delay for sending data
   *
   * @memberof Incomplete
   */
  sendBounce () {
    // Cancel old debounce if set
    if ( this.debounce.send ) {
      this.debounce.send.cancel()
    }
    // Create new debounce
    this.debounce.send = _.debounce( () => {
      this.send()
    }, 3000 )()
  }

  /**
   * Sets force send timer,
   * timer is used to force send even when debounce is active
   *
   * @memberof Incomplete
   */
  setForceTimer () {
    this.forceSend = {
      activated: true,
      timer: setTimeout( this.send.bind( this ), 10000 )
    }
  }

  /**
   * Clears force send timer, usually used after send,
   * there is no need to run timer indefinitely
   *
   * @memberof Incomplete
   */
  clearForceTimer () {
    if ( this.forceSend ) {
      clearTimeout( this.forceSend.timer )
      this.forceSend.activated = false
    }
  }
}
