var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.tooltip }),expression:"{ content: tooltip }"}],staticClass:"field"},[_c('div',{staticClass:"control",class:{ 'has-float-label': _vm.label, 'has-icons-right': _vm.icon.length }},[_c('input',{ref:"input",staticClass:"input",class:[{
        'is-danger': _vm.error,
        'is-warning': _vm.warning,
        'is-static': _vm.isStatic,
        'placeholder-not-shown': !_vm.hasValue
      }, _vm.inputClass],attrs:{"autocomplete":"off","id":("input_" + _vm.name),"placeholder":_vm.placeholder,"name":_vm.name,"disabled":_vm.disabled,"type":_vm.type,"readonly":_vm.isStatic,"step":_vm.type === 'number' ? 'any' : null},domProps:{"value":_vm.isStatic ? _vm.value || '-' : _vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.enterAction.apply(null, arguments)},"change":function($event){return _vm.$emit('change', $event.target.value)},"input":function($event){return _vm.updateValue($event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"label",attrs:{"for":("input_" + _vm.name)}},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]),_vm._v(" "),(_vm.icon.length)?_c('div',{staticClass:"icon is-small is-right"},[_c('font-awesome-icon',{attrs:{"icon":_vm.icon}})],1):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"slideDown"}},[(_vm.error || _vm.warning)?_c('p',{staticClass:"help",class:{
      'is-danger': _vm.error,
      'is-warning': _vm.warning,
    }},[_vm._v("\n      "+_vm._s(_vm.error || _vm.warning)+"\n    ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }