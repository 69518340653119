export default {
  methods: {
    /**
     * Updates loan slider values to giosg extranet
     */
    updateGiosgValues () {
      try {
        window._giosg( () => {
          window.giosg.api.shoppingCart.submit( [{
            name: 'loan',
            price: this.form.luottoraja,
            quantity: 1,
            monthly_plan_length: this.form.maksuaika
          }] )
        } )
      }
      catch ( error ) {
        // Ignore error
      }
    }
  }
}
