<template>
  <div class="app">
    <template v-if="$route.query.source === 'cc_extranet'">
      <h1 v-if="showID"
          class="is-size-5"
      >
        Hakemus Lähetetty. Uuden hakemuksen ID:
      </h1>
      <p class="is-size-5 my-2"><b>{{ id }}</b></p>
     <!--<button class="button is-primary is-rounded mt-1" @click="copyID">
        Kopioi ID
      </button> -->
      <button @click="submit">
        Jatka
      </button>
    </template>
    <template v-else>
      <div class="app">
        <div class="app-section app-section_header">
          <p class="title is-4 has-text-rlgreen">Kiitos lainahakemuksesta</p>
          <div class="content">
            <p v-if="$store.state.whitelabel">Lainahakemuksesi on välitetty lainojen kilpailuttamiseen erikoistuneelle kumppanillemme Rahalaitokselle, joka hoitaa lainasi kilpailuttamisen tästä eteenpäin. Olet saanut heiltä vahvistuksen tästä sähköpostiisi ja puhelimeesi.</p>
            <p>Lainahakemuksenne on nyt käsittelyssä. Voitte seurata lainaprosessin etenemistä reaaliajassa kirjautumalla <nuxt-link to="/oma">Oma Rahalaitos</nuxt-link>  -palveluun.</p>
            <p>Saatte alustavan lainapäätöksen hakemuksellenne jopa minuuteissa. Lainapäätöksistä ilmoitetaan myös sähköpostilla sekä tekstiviestillä.</p>
          </div>
          <p class="title is-6 has-text-rlgreen">Vastaamalla alla oleviin kysymyksiin autatte meitä kehittämään palveluamme:</p>
          <form @submit.prevent>
            <div class="columns">
              <div class="column is-6">
                <p class="has-no-wrap-desktop">Mistä saitte tietoa palvelusta?</p>
                <form-select
                  key="form.mista"
                  v-model="form.mista"
                  v-validate="'required'"
                  :error="errors.first('mista')"
                  name="mista"
                  placeholder="Mistä saitte tietoa"
                >
                  <option value="1">Radiomainos</option>
                  <option value="2">Lehtimainos</option>
                  <option value="3">Toisen Internet-sivun kautta</option>
                  <option value="4">Kuulin kaverilta</option>
                  <option value="5">Hakukone</option>
                  <option value="6">TV-mainos</option>
                  <option value="9">Sähköposti</option>
                  <option value="8">Vanha asiakas</option>
                  <option value="7">Jokin muu</option>
                </form-select>
              </div>
            </div>
            <div class="columns">
              <div class="column has-items-end">
                <button :disabled="data.submitDisabled || data.submitLoading" class="button is-primary is-rounded is-3d" type="button" @click="submit">
                  <span>Jatka tarjouksiin</span><span v-if="data.submitLoading" class="icon"><font-awesome-icon :icon="['fas', 'spinner-third']" spin /></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import handleFieldError from '~/mixins/application/handleFieldError'
import thankYou from '~/mixins/application/thankYou'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formSelect: () => import( '~/components/form/select' )
  },
  mixins: [
    handleFieldError,
    thankYou
  ],
  props: {
    id: {
      type: String,
      required: true
    },
    redirectUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      data: {
        submitDisabled: false,
        submitLoading: false
      },
      form: {
        action: 'survey',
        mista: ''
      }
    }
  },
  mounted () {
    this.$nextTick( () => {
      this.$el.scrollIntoView( {
        behavior: 'smooth',
        block: 'start'
      } )
    } )
  },
  methods: {
    async sendForm () {
      let result = {}
      try {
        result = await this.$axios.$post( '/v1/?action=survey', { id: this.id, ...this.form } )
      }
      catch ( error ) {
        this.$logger.error( error )
      }
      if ( Object.prototype.hasOwnProperty.call( result, 'success' ) && result.success === true ) {
        this.$emit( 'sent', result )
        return true
      }
      else if ( Object.prototype.hasOwnProperty.call( result, 'errors' ) ) {
        this.handleFieldError( result.errors )
        return false
      }
      return false
    }
   /*  copyID () {
      navigator.clipboard.writeText( this.id )
      alert( 'Kopioitu ID: ' + this.id )
    } */
  }
}
</script>
