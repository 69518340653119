<template>
  <div v-tooltip="{ content: tooltip }" class="field">
    <div :class="{ 'has-float-label': label, 'has-icons-right': icon.length }" class="control">
      <input
        autocomplete="off"
        :id="`input_${name}`"
        ref="input"
        :placeholder="placeholder"
        :name="name"
        :value="isStatic ? value || '-' : value"
        :disabled="disabled"
        :class="[{
          'is-danger': error,
          'is-warning': warning,
          'is-static': isStatic,
          'placeholder-not-shown': !hasValue
        }, inputClass]"
        :type="type"
        :readonly="isStatic"
        :step="type === 'number' ? 'any' : null"
        class="input"
        @keyup.enter="enterAction"
        @change="$emit('change', $event.target.value)"
        @input="updateValue($event.target.value)"
      >
      <label :for="`input_${name}`" class="label">
        {{ label }}
      </label>
      <div v-if="icon.length" class="icon is-small is-right">
        <font-awesome-icon :icon="icon" />
      </div>
    </div>
    <transition name="slideDown">
      <p v-if="error || warning" class="help" :class="{
        'is-danger': error,
        'is-warning': warning,
      }"
      >
        {{ error || warning }}
      </p>
    </transition>
  </div>
</template>

<script>
import { createTextMaskInputElement } from 'text-mask-core'

export default {
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    },
    alias () {
      return this.label || this.placeholder || this.name
    }
  },
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    enterAction: {
      default: () => undefined,
      type: Function
    },
    error: {
      default: '',
      type: String
    },
    warning: {
      default: '',
      type: String
    },
    icon: {
      default: () => [],
      type: Array
    },
    inputClass: {
      default: '',
      type: String
    },
    isStatic: {
      default: false,
      type: Boolean
    },
    label: {
      default: '',
      type: String
    },
    mask: {
      default: () => null,
      type: Object
    },
    name: {
      required: true,
      type: String
    },
    placeholder: {
      default: ' ',
      type: String
    },
    tooltip: {
      default: '',
      type: String
    },
    type: {
      default: 'text',
      type: String
    },
    value: {
      default: null,
      type: [String, Number]
    }
  },
  data () {
    return {
      textMaskInputElement: null
    }
  },
  computed: {
    hasValue () {
      return this.value !== ''
    }
  },
  mounted () {
    if ( this.mask ) this.initMask()
  },
  methods: {
    initMask () {
      this.setTextMaskInputElement()
      this.textMaskInputElement.update( this.value )
    },
    setTextMaskInputElement () {
      this.textMaskInputElement = createTextMaskInputElement( {
        inputElement: this.$refs.input,
        ...this.mask
      } )
    },
    bind () {
      this.setTextMaskInputElement()
      this.updateValue( this.value )
    },
    updateValue ( value ) {
      if ( this.textMaskInputElement !== null ) {
        this.textMaskInputElement.update( value )
        this.$emit( 'input', this.$refs.input.value )
      }
      else {
        this.$emit( 'input', value )
      }
    }
  }
}
</script>
