import { isPlainObject, isArray } from 'lodash'
import { parse } from 'qs'
/**
 * Filters out unwanted fields form dataset
 *
 * @export
 * @param   {array}        fields    Fields to keep
 * @param   {array|object} data      Array or object to filter
 * @param   {bool}         flattened Return flattened fields
 * @returns {array|object}
 */
export function fieldFilter ( fields, data, flattened = false ) {
  const parsed = flatten( data )
  const result = {}
  fields.forEach( field => {
    result[field] = parsed[field]
  } )
  return flattened ? result : parse( result )
}

/**
 * Flattens the object
 *
 * @export
 * @param {object} data
 * @param {string} [prefix=null]
 * @returns
 */
export function flatten ( data, prefix = null ) {
  const result = {}
  Object.keys( data ).forEach( key => {
    if ( isArray( data[key] ) || isPlainObject( data[key] ) ) {
      Object.assign( result, flatten( data[key], prefix ? `${prefix}[${key}]` : key ) )
    }
    else {
      prefix ? ( result[`${prefix}[${key}]`] = data[key] ) : ( result[key] = data[key] )
    }
  } )
  return result
}

/**
 * Adds default fields to result
 *
 * @export
 * @param {object} addTo
 * @param {object} addFrom
 * @returns
 */
export function addDefaults ( addTo, addFrom ) {
  const defaults = ['luottoraja', 'maksuaika']
  defaults.forEach( def => {
    if ( def in addFrom ) {
      addTo[def] = addFrom[def]
    }
  } )
  return addTo
}
