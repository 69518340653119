
<script>
import _ from 'lodash'
/**
 * @copyright 2018 Daniel Diekmeier, Kahiro Okina
 * @see https://github.com/k-okina/vue-slide-up-down-component/
 */
export default {
  name: 'SlideUpDown',

  props: {
    active: Boolean,
    duration: {
      type: Number,
      default: 500
    },
    tag: {
      type: String,
      default: 'div'
    },
    closedHeight: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      maxHeight: this.closedHeight,
      opened: false,
      isInitialized: false
    }
  },
  computed: {
    style () {
      if ( this.opened ) return {}
      const baseStyle = {
        overflow: 'hidden',
        'max-height': `${this.maxHeight}px`
      }

      if ( this.isInitialized ) {
        return Object.assign( {}, baseStyle, {
          'transition-property': 'max-height',
          'transition-duration': `${this.duration}ms`
        } )
      }
      return baseStyle
    }
  },
  watch: {
    active: {
      handler () {
        this.layout()
      },
      immediate: true
    }
  },
  mounted () {
    _.debounce( () => {
      window.addEventListener( 'resize', this.layout )
    }, 500 )
  },
  destroyed () {
    window.removeEventListener( 'resize', this.layout )
  },
  methods: {
    layout () {
      if ( this.active ) {
        if ( !this.isInitialized ) {
          this.isInitialized = true
          this.opened = true
        }
        else {
          this.isInitialized = true
          this.maxHeight = this.$el.scrollHeight
          setTimeout( () => {
            if ( this.active ) {
              this.opened = true
            }
          }, this.duration )
        }
      }
      else {
        if ( !this.isInitialized ) {
          this.isInitialized = true
          return
        }
        this.opened = false
        this.maxHeight = this.$el.scrollHeight
        this.$nextTick( () => {
          setTimeout( () => {
            if ( !this.active ) {
              if ( this.maxHeight > this.closedHeight ) {
                this.maxHeight = this.closedHeight
              }
            }
          }, 10 )
        } )
      }
    }
  },
  render ( h ) {
    return h(
      this.tag,
      { style: this.style },
      this.$slots.default
    )
  }
}
</script>
