<template>
  <div>
    <div class="app-section">
      <p class="is-uppercase has-text-weight-bold subtitle is-6">Yhteishakija</p>
      <div class="notification is-primary">
        <div class="columns">
          <div class="column is-4">
            <p class="has-no-wrap-desktop">Yhteishakija asuu samassa osoitteessa</p>
            <form-select
              key="$parent.form.yv_asuu"
              v-model="$parent.form.yv_asuu"
              v-validate="'required'"
              :error="errors.first('yv_asuu')"
              name="yv_asuu"
              placeholder="Valitse"
              @input="$parent.coSameAddress"
            >
              <option value="Kyllä">Kyllä</option>
              <option value="Ei">Ei</option>
            </form-select>
          </div>
        </div>
      </div>
    </div>
    <template v-if="$parent.form.yv_asuu">
      <div class="app-section">
        <p class="is-uppercase has-text-weight-bold subtitle is-6">Henkilö- ja yhteystiedot</p>
        <div class="columns is-multiline">
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="$parent.form.yv_sotu"
              v-model="$parent.form.yv_sotu"
              v-validate="'required|finnish_ssn|age_min:18|age_max:75|not_same:sotu'"
              v-uppercase
              :error="errors.first('yv_sotu')"
              name="yv_sotu"
              label="Henkilötunnus"
              placeholder="Henkilötunnus"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="$parent.form.yv_etunimi"
              v-model="$parent.form.yv_etunimi"
              v-validate="'required|min:2'"
              :error="errors.first('yv_etunimi')"
              name="yv_etunimi"
              label="Etunimi"
              placeholder="Etunimi"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="$parent.form.yv_sukunimi"
              v-model="$parent.form.yv_sukunimi"
              v-validate="'required|min:2'"
              :error="errors.first('yv_sukunimi')"
              name="yv_sukunimi"
              label="Sukunimi"
              placeholder="Sukunimi"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="$parent.form.yv_osoite"
              v-model="$parent.form.yv_osoite"
              v-validate="'required|max:128'"
              :disabled="$parent.form.yv_asuu === 'Kyllä'"
              :error="errors.first('yv_osoite')"
              name="yv_osoite"
              label="Katuosoite"
              placeholder="Katuosoite"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="$parent.form.yv_postinro"
              v-model="$parent.form.yv_postinro"
              v-validate="'required|digits:5'"
              :disabled="$parent.form.yv_asuu === 'Kyllä'"
              :error="errors.first('yv_postinro')"
              name="yv_postinro"
              type="number"
              label="Postinumero"
              placeholder="Postinumero"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="$parent.form.yv_kaupunki"
              v-model="$parent.form.yv_kaupunki"
              v-validate="'required|alpha_spaces|min:2|max:128'"
              :disabled="$parent.form.yv_asuu === 'Kyllä'"
              :error="errors.first('yv_kaupunki')"
              name="yv_kaupunki"
              label="Postitoimipaikka"
              placeholder="Postitoimipaikka"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="$parent.form.yv_puhelin"
              v-model="$parent.form.yv_puhelin"
              v-validate="'required'"
              :error="errors.first('yv_puhelin')"
              name="yv_puhelin"
              type="tel"
              label="Puhelinnumero"
              placeholder="esim. 040 123 4567"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-input
              key="$parent.form.yv_email"
              v-model="$parent.form.yv_email"
              v-validate="'required|email|not_same:email'"
              :error="errors.first('yv_email')"
              name="yv_email"
              type="email"
              label="Sähköpostiosoite"
              placeholder="Sähköpostiosoite"
            />
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-select
              key="$parent.form.yv_siviilisaaty"
              v-model="$parent.form.yv_siviilisaaty"
              v-validate="'required'"
              :error="errors.first('yv_siviilisaaty')"
              name="yv_siviilisaaty"
              label="Siviilisääty"
              placeholder="Siviilisääty"
            >
              <option value="Naimisissa">Naimisissa</option>
              <option value="Avoliitto">Avoliitto</option>
              <option value="Naimaton">Naimaton</option>
              <option value="Eronnut">Eronnut</option>
              <option value="Leski">Leski</option>
            </form-select>
          </div>
        </div>
      </div>
      <div class="app-section">
        <p class="is-uppercase has-text-weight-bold subtitle is-6">Työsuhde</p>
        <div class="columns is-multiline">
          <div class="column is-4 no-padding-bottom">
            <form-select
              key="$parent.form.yv_tyosuhde"
              v-model="$parent.form.yv_tyosuhde"
              v-validate="'required'"
              :error="errors.first('yv_tyosuhde')"
              name="yv_tyosuhde"
              label="Työsuhde"
              placeholder="Työsuhde"
            >
              <option value="Vakituinen">Vakinainen</option>
              <option value="Määräaikainen">Määräaikainen</option>
              <option value="Projektityöntekijä">Projektityöntekijä</option>
              <option value="Yrittäjä">Yrittäjä</option>
              <option value="Eläkeläinen">Eläkeläinen</option>
            </form-select>
          </div>
          <div class="column is-4 no-padding-bottom">
            <form-select
              key="$parent.form.yv_koulutus"
              v-model="$parent.form.yv_koulutus"
              v-validate="'required'"
              :error="errors.first('yv_koulutus')"
              name="yv_koulutus"
              label="Koulutus"
              placeholder="Koulutus"
            >
              <option value="peruskoulu">Peruskoulu</option>
              <option value="lukio">Lukio</option>
              <option value="ammattikoulu">Ammattikoulu</option>
              <option value="Alempi korkeakoulututkinto">Alempi korkeakoulututkinto</option>
              <option value="Ylempi korkeakoulututkinto">Ylempi korkeakoulututkinto</option>
            </form-select>
          </div>
          <template v-if="$parent.form.yv_tyosuhde !== 'Yrittäjä' && $parent.form.yv_tyosuhde !== 'Eläkeläinen' && $parent.form.yv_tyosuhde">
            <div class="column is-4 no-padding-bottom">
              <datepicker
                key="$parent.form.yv_tyonalkupvm"
                v-model="$parent.form.yv_tyonalkupvm"
                v-validate="'required'"
                :error="errors.first('yv_tyonalkupvm')"
                minimum-view="month"
                maximum-view="year"
                initial-view="year"
                name="yv_tyonalkupvm"
                label="Työsuhde alkanut"
                placeholder="Työsuhde alkanut"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="$parent.form.yv_tyonantaja"
                v-model="$parent.form.yv_tyonantaja"
                v-validate="'required|max:128'"
                :error="errors.first('yv_tyonantaja')"
                name="yv_tyonantaja"
                label="Työnantaja"
                placeholder="Työnantaja"
              />
            </div>
          </template>
          <template v-if="$parent.form.yv_tyosuhde === 'Yrittäjä'">
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="$parent.form.yv_yritysnimi"
                v-model="$parent.form.yv_yritysnimi"
                v-validate="'required'"
                :error="errors.first('yv_yritysnimi')"
                name="yv_yritysnimi"
                label="Yrityksen nimi"
                placeholder="Yrityksen nimi"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="$parent.form.yv_ytunnus"
                v-model="$parent.form.yv_ytunnus"
                v-validate="'required'"
                :error="errors.first('yv_ytunnus')"
                name="yv_ytunnus"
                label="Y-tunnus"
                placeholder="Y-tunnus"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <datepicker
                key="$parent.form.yv_rekpaiva"
                v-model="$parent.form.yv_rekpaiva"
                v-validate="'required'"
                :error="errors.first('yv_rekpaiva')"
                minimum-view="month"
                maximum-view="year"
                initial-view="year"
                name="yv_rekpaiva"
                label="Yrityksen rekisteröintipäivämäärä"
                placeholder="Yrityksen rekisteröintipäivämäärä"
              />
            </div>
          </template>
          <template v-if="$parent.form.yv_tyosuhde === 'Eläkeläinen'">
            <div class="column is-4 no-padding-bottom">
              <datepicker
                key="$parent.form.yv_tyonalkupvm"
                v-model="$parent.form.yv_tyonalkupvm"
                v-validate="'required'"
                :error="errors.first('yv_tyonalkupvm')"
                minimum-view="month"
                maximum-view="year"
                initial-view="year"
                name="yv_tyonalkupvm"
                label="Eläkkeen alkamispäivämäärä"
                placeholder="Eläkkeen alkamispäivämäärä"
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="$parent.form.yv_tyonantaja"
                v-model="$parent.form.yv_tyonantaja"
                v-validate="'required|min:3'"
                :error="errors.first('yv_tyonantaja')"
                name="yv_tyonantaja"
                label="Eläkelaitos"
                placeholder="Eläkelaitos"
              />
            </div>
          </template>
          <div v-if="$parent.form.yv_tyosuhde === 'Määräaikainen' || $parent.form.yv_tyosuhde === 'Projektityöntekijä'" class="column is-4 no-padding-bottom">
            <datepicker
              key="$parent.form.yv_maaraaikapvm"
              v-model="$parent.form.yv_maaraaikapvm"
              v-validate="'required'"
              :error="errors.first('yv_maaraaikapvm')"
              :disabled-dates="{ to: new Date() }"
              minimum-view="month"
              maximum-view="year"
              initial-view="year"
              name="yv_maaraaikapvm"
              label="Työsuhteen voimassaoloaika"
              placeholder="Työsuhteen voimassaoloaika"
            />
          </div>
          <template v-if="$parent.form.yv_tyosuhde">
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="$parent.form.yv_bruttotulot"
                v-model="$parent.form.yv_bruttotulot"
                v-validate="'required|numeric_spaces|min_value_stripped:420'"
                :error="errors.first('yv_bruttotulot')"
                :mask="{ mask: masks.euro, guide: false }"
                :icon="['far', 'euro-sign']"
                name="yv_bruttotulot"
                type="tel"
                label="Bruttotulot kuukaudessa"
                placeholder="Bruttotulotkuukaudessa"
                tooltip="Ilmoita kuukausitulosi ennen veroja. Bruttokuukausitulojen täytyy olla vähintään 420 euroa."
              />
            </div>
            <div class="column is-4 no-padding-bottom">
              <form-input
                key="$parent.form.yv_nettotulot"
                v-model="$parent.form.yv_nettotulot"
                v-validate="'required|numeric_spaces|min_value_stripped:420'"
                :error="errors.first('yv_nettotulot')"
                :mask="{ mask: masks.euro, guide: false }"
                :icon="['far', 'euro-sign']"
                name="yv_nettotulot"
                type="tel"
                label="Nettotulot kuukaudessa"
                placeholder="Nettotulot kuukaudessa"
                tooltip="Ilmoita kuukausitulosi verojen jälkeen. Nettokuukausitulojen täytyy olla vähintään 420 euroa."
              />
            </div>
            <!-- <div class="column is-4 no-padding-bottom">
              <form-input
                ref="$parent.yv_elinkustannukset"
                key="$parent.form.yv_elinkustannukset"
                v-model="$parent.form.yv_elinkustannukset"
                v-validate="{
                  required: true,
                  numeric_spaces: true,
                }"
                :error="errors.first('yv_elinkustannukset')"
                :mask="{ mask: masks.euro, guide: false }"
                :icon="['far', 'euro-sign']"
                name="yv_elinkustannukset"
                type="tel"
                label="Elinkustannukset kuukaudessa"
                placeholder="Elinkustannukset kuukaudessa"
                tooltip="Kuinka suuret ovat kuukausittaiset kiinteät kustannuksesi? Huomioi tassä kentassä, kuinka paljon käytät mm. ruokaan. vaatteisiin. harrastuksiin. vakuutuksiin. sähköön, tervevdenhuoltoon ja liikkumiseen."
              />
            </div> -->
          </template>
        </div>
        <div class="columns">
          <div class="column is-4">
            <p class="has-no-wrap-desktop">Onko yhteishakijalla lainoja tai luottokortteja</p>
            <form-select
              key="$parent.form.yv_lainoja"
              v-model="$parent.form.yv_lainoja"
              v-validate="'required'"
              :error="errors.first('yv_lainoja')"
              name="yv_lainoja"
              placeholder="Valitse"
            >
              <option value="Kyllä">Kyllä</option>
              <option value="Ei">Ei</option>
            </form-select>
          </div>
        </div>
      </div>
      <div v-if="$parent.form.yv_lainoja === 'Kyllä'" class="app-section">
        <p class="is-uppercase has-text-weight-bold subtitle is-6">Lainat</p>
        <loan-picker
          key="$parent.form.Loans.CoApplicant"
          v-model="$parent.form.Loans.CoApplicant"
          name="yv_lainatlkm"
          prefix="CoApplicant"
          :refinance="false"
          :count="$parent.form.yv_lainatlkm"
          @countChange="$parent.form.yv_lainatlkm = $event"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { euro } from '~/utils/form/masks'

export default {
  inject: ['$validator'],
  components: {
    datepicker: () => import( '~/components/form/datepicker' ),
    formInput: () => import( '~/components/form/input' ),
    formSelect: () => import( '~/components/form/select' ),
    loanPicker: () => import( '~/components/form/loanPicker' )
  },
  data () {
    return {
      masks: {
        euro
      }
    }
  }
}
</script>
