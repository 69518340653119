import { addDefaults, fieldFilter } from '~/utils/form/data'

/**
 * Multiples the given value by 12 when it exists
 * @param {string|undefined} field
 * @returns
 */
const multiplyIncome = function ( field ) {
  let result
  if ( field ) {
    result = parseInt( field.replace( / /g, '' ) ) * 12
  }
  return result
}

export default {
  methods: {
    /**
     * Sends full application
     *
     * @retuns {boolean}
     */
    async sendApplication () {
      const data = this.getData()
      // Store monthly from interestRate when available
      // (Not available for Business loan)
      if ( this.$refs.interestRate ) {
        data.kkera = this.$refs.interestRate.monthly
      }
      // Multiply income fields by 12 to get annual income.
      data.bruttotulot = multiplyIncome( data.bruttotulot )
      data.nettotulot = multiplyIncome( data.nettotulot )
      if ( data.yv_hakija ) {
        data.yv_bruttotulot = multiplyIncome( data.yv_bruttotulot )
        data.yv_nettotulot = multiplyIncome( data.yv_nettotulot )
      }
      let result = {}
      try {
        result = await this.$axios.$post( '/v1/?action=store', data )
      }
      catch ( error ) {
        this.$logger.error( error )
      }
      if (
        Object.prototype.hasOwnProperty.call( result, 'success' ) &&
        result.success === true
      ) {
        // Clear incomplete watchers
        Object.keys( this.watchers.form ).forEach( ( unwatch ) => {
          this.watchers.form[unwatch]()
        } )
        // Clear incomplete
        this.data.incomplete = null
        // Show confirmation or thank you page
        this.$emit( 'sent', result, this.form )
        return true
      }
      else if (
        Object.prototype.hasOwnProperty.call( result, 'redis' ) &&
        result.redis === true
      ) {
        // Clear incomplete watchers
        Object.keys( this.watchers.form ).forEach( ( unwatch ) => {
          this.watchers.form[unwatch]()
        } )
        this.data.incomplete = null

        this.$logger.info(
          {
            name: 'DatabaseError',
            message: 'Hakemus tallennettu Redikseen.'
          }, { metaData: result }
        )
        this.data.applicationError = `
          <strong>Hakemuksesi on tallennettu</strong>
          <p>
            Hakemuksesi menee käsittelyyn pian.
            Saat tekstiviestitse ilmoituksen,
            kun pääset katsomaan saamiasi tarjouksia.
            Voit poistua sivustolta.
          </p>`

        return true
      }
      else if ( Object.prototype.hasOwnProperty.call( result, 'errors' ) ) {
        this.handleFieldError( result.errors )
        return false
      }
      this.$logger.info( { name: 'ValidationRequestError', message: 'No data' }, { metaData: result } )
      this.data.applicationError = `
        <strong>Järjestelmävirhe</strong>
        <p>Emme voineet vastaanottaa hakemustanne, koska tapahtui odottamaton järjestelmävirhe.</p>
      `
      return false
    },
    /**
     * Returns application data
     */
    getData () {
      return addDefaults(
        fieldFilter( Object.keys( this.fields ), this.form ), this.form
      )
    },
    /**
     * Cleans error elements from application
     */
    clearErrors () {
      this.data.applicationError = null
      this.data.unemployedError = null
    }
  }
}
