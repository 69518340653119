import { FinnishSSN } from 'finnish-ssn'

export default {
  methods: {
    /**
     * Calculates main applicant age
     *
     * @param {string} ssn - Social security number.
     */
    calculateAge ( ssn ) {
      try {
        return FinnishSSN.parse(
          ssn.trim()
        ).ageInYears
      }
      // Ignore Not valid SSN from bugsnag.
      catch ( error ) {
        if ( error.message === 'Not valid SSN format' ) {
          return null
        }
        throw error
      }
    },
    /**
     * Get applicant gender based on SSN
     *
     * @param {string} ssn - Social security number.
     */
    calculateGender (ssn) {
      if (this.calculateAge(ssn) > 0) {
        let gen
        if (ssn[9] % 2 === 0) {
          gen = 'F'
        }
        else {
          gen = 'M'
        }
        return gen
      }
    }
  }
}
